<template>
  <v-dialog :value="value" persistent max-width="800" width="100%">
    <v-card outlined class="pa-5">
      <v-progress-linear
        v-if="loadingRecoveryCodes"
        absolute
        top
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-title class="pt-0 px-0">
        {{ $t("frontend.pages.firstRun.twoFactorAuth.recoveryCodes.title") }}
      </v-card-title>
      <v-alert text dense prominent type="warning">
        {{ $t("frontend.pages.firstRun.twoFactorAuth.recoveryCodes.text") }}
      </v-alert>
      <v-sheet outlined rounded class="pa-5">
        <v-list-item v-for="code in recoveryCodes" :key="code" dense>{{
          code
        }}</v-list-item>
      </v-sheet>
      <v-checkbox
        v-model="recoveryCodesConfirmed"
        :label="
          $t(
            'frontend.pages.firstRun.twoFactorAuth.recoveryCodes.confirmationCheck'
          )
        "
      ></v-checkbox>
      <v-card-actions class="pb-0 px-0">
        <slot name="action-buttons"></slot>
        <v-spacer />
        <v-btn
          large
          depressed
          :disabled="!recoveryCodesConfirmed"
          color="primary"
          @click="nextStep"
        >
          <span v-if="firstRun === true">{{
            $t("general.buttons.forward")
          }}</span>
          <span v-else>OK</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TwoFactorAuthenticationRecoveryCodesDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    recoveryCodes: {
      type: Array,
      default: () => [],
    },
    firstRun: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingRecoveryCodes: false,
    recoveryCodesConfirmed: false,
  }),
  methods: {
    async nextStep() {
      this.$emit("dialog:close");
      this.recoveryCodesConfirmed = false;
      await this.fetchAuthUser().then(() => {
        if (this.firstRun) {
          this.$router.push({ name: "dashboard" });
        }
      });
    },
    ...mapActions("User", ["fetchAuthUser"]),
  },
};
</script>

<style scoped></style>
