<template>
  <v-dialog
    :value="value"
    persistent
    max-width="800"
    :overlay-opacity="overlayOpacity"
  >
    <v-card class="pa-5">
      <v-card-title class="px-0 pt-0">
        <slot name="card-title"></slot>
        <v-spacer />
        <v-btn :disabled="loading" icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <slot></slot>
      <v-card-actions class="px-0 pb-0">
        <v-btn
          depressed
          :disabled="loading"
          :loading="loading"
          large
          class="mr-2"
          @click="close"
          ><slot name="abort-button-text">Abbrechen</slot></v-btn
        >
        <v-btn
          depressed
          :disabled="loading"
          :loading="loading"
          large
          color="primary"
          class="white--text"
          @click="submit"
          ><slot name="action-button-text">Ok</slot></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    overlayOpacity: {
      type: String || Number || undefined,
      default: undefined,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      this.$emit("dialog:action");
    },
    close() {
      this.$emit("dialog:close");
    },
  },
};
</script>

<style scoped></style>
