<template>
  <v-container class="fill-height justify-center">
    <v-sheet width="100%" max-width="1140">
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="(header, index) in stepperHeaders">
            <v-stepper-step
              :key="`header-${index}`"
              :complete="currentStep > header.step"
              :color="currentStep > header.step ? 'success' : 'primary'"
              :step="header.step"
            >
              {{ header.text }}
            </v-stepper-step>
            <v-divider
              v-if="index + 1 !== stepperHeaders.length"
              :key="`divider-${index}`"
            />
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <VerifyEmail />
          </v-stepper-content>
          <v-stepper-content step="2">
            <FirstLoginChangePassword />
          </v-stepper-content>
          <v-stepper-content step="3">
            <FirstLoginTwoFactorAuth />
          </v-stepper-content>
          <v-stepper-content step="4">
            <FirstLoginNewsletter />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-sheet>
  </v-container>
</template>

<script>
// import { isEmpty } from "lodash";

import VerifyEmail from "../components/init/VerifyEmail";
import FirstLoginChangePassword from "../components/init/FirstLoginChangePassword";
import FirstLoginTwoFactorAuth from "../components/init/FirstLoginTwoFactorAuth";
import FirstLoginNewsletter from "@/components/init/FirstLoginNewsletter.vue";

export default {
  name: "FirstRun",
  components: {
    FirstLoginNewsletter,
    FirstLoginTwoFactorAuth,
    FirstLoginChangePassword,
    VerifyEmail,
  },
  data: () => ({
    currentStep: 1,
  }),
  props: ["type"],
  created() {
    if (this.verifyEmailRoute) {
      this.currentStep = 1;
    } else if (this.setCredentialsRoute) {
      this.currentStep = 2;
    } else if (this.twoFactorRoute) {
      this.currentStep = 3;
    } else if (this.newsletterRoute) {
      this.currentStep = 4;
    }
  },
  computed: {
    stepperHeaders() {
      return [
        {
          step: 1,
          text: this.$t("frontend.pages.firstRun.emailVerification.title"),
        },
        { step: 2, text: this.$t("frontend.pages.firstRun.credentials.title") },
        {
          step: 3,
          text: this.$t("frontend.pages.firstRun.twoFactorAuth.title"),
        },
        { step: 4, text: this.$t("frontend.pages.firstRun.newsletter.title") },
      ];
    },
    authUser() {
      return this.$store.state.User.authUser;
    },
    verifyEmailRoute() {
      return this.$route.name === "verify-email";
    },
    setCredentialsRoute() {
      return this.$route.name === "first-run-change-password";
    },
    twoFactorRoute() {
      return this.$route.name === "first-run-enable-two-factor-auth";
    },
    newsletterRoute() {
      return this.$route.name === "first-run-newsletter";
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "first-run-change-password") {
      this.$nextTick(() => {
        this.currentStep = 2;
      });
    } else if (to.name === "first-run-enable-two-factor-auth") {
      this.$nextTick(() => {
        this.currentStep = 3;
      });
    } else if (to.name === "first-run-newsletter") {
      this.$nextTick(() => {
        this.currentStep = 4;
      });
    }
    next();
  },
};
</script>

<style scoped></style>
