<template>
  <v-app>
    <v-app-bar app flat dark height="72">
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>

      <!-- Sprache -->
      <LocaleSwitcher />

      <v-spacer />
      <div
        v-if="!$hasAnyRole(authUser, 'Arzt')"
        class="d-flex flex-grow-1 searchbar"
      >
        <v-text-field
          v-if="$vuetify.breakpoint.mdAndUp"
          v-model="search"
          hide-details
          outlined
          dense
          clearable
          :placeholder="
            $hasAnyRole(authUser, 'Arzt')
              ? searchPlaceholderDoctors
              : searchPlaceholder
          "
          append-icon="mdi-magnify"
          @click:clear="onResetSearch"
          @input="sendSearchQuery"
        >
        </v-text-field>
        <SearchResults class="searchresults" />
      </div>
      <v-spacer />
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown && !$hasAnyRole(authUser, 'Arzt')"
        icon
        @click="searchDialog = true"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn
        v-if="$can('access forum')"
        depressed
        :to="{ name: 'list-postgroups' }"
        >Forum</v-btn
      >

      <!-- Aktivitäten -->
      <ActivitiesContainer />

      <!-- Account -->
      <v-menu offset-x offset-y min-width="200">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
            <v-avatar color="primary" class="font-weight-bold white--text">
              {{ authUserInitials }}
            </v-avatar>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item
            link
            exact
            :to="`/backend/users/${authUser.uuid}/account`"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("backend.navbar.account") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('access system settings')"
            link
            exact
            to="/backend/system"
          >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("backend.navbar.settings") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('list users')"
            link
            exact
            to="/backend/system/users"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Benutzer </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item link @click="onLogout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("backend.navbar.logout") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      :fixed="$vuetify.breakpoint.smAndDown"
      :mini-variant.sync="mini"
      :temporary="$vuetify.breakpoint.smAndDown"
      dark
    >
      <v-list-item style="background-color: #272727">
        <v-list-item-icon>
          <img
            alt="IGL Logo"
            class="shrink"
            :src="require('../assets/igl_logo_2021.svg')"
            width="40"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Labor</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list dense nav>
        <template v-for="navItem in navigationItems">
          <v-list-group
            v-if="navItem.children && checkGates(navItem.gate)"
            :key="navItem.title"
            :prepend-icon="navItem.icon"
            v-model="navItem.active"
            class="mb-1"
            active-class="list-group-active"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-badge
                  v-if="navItem.title === 'backend.sidebar.reports'"
                  :value="showBadgeForReportsGroup"
                  dot
                  color="info"
                >
                  <v-list-item-title>
                    {{ $t(navItem.title) }}
                  </v-list-item-title>
                </v-badge>
                <v-list-item-title v-else>
                  {{ $t(navItem.title) }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-for="(child, index) in navItem.children">
              <v-list-item
                v-if="!child.gate || $can(child.gate)"
                :key="`nav-child-${index}`"
                class="pl-5"
                :to="child.path"
                link
                :exact="child.exact"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-badge
                    v-if="child.title === 'backend.sidebar.iec-tool'"
                    :content="iecToolSelectionCount"
                    :value="iecToolSelectionPresent"
                    inline
                    color="info"
                  >
                    <v-list-item-title>
                      {{ $t(child.title) }}
                    </v-list-item-title>
                  </v-badge>
                  <v-badge
                    v-else-if="
                      child.title === 'backend.sidebar.reportsOverview'
                    "
                    :content="unreadReportsCount"
                    :value="unreadReportsPresent"
                    inline
                    color="info"
                  >
                    <v-list-item-title>
                      {{ $t(child.title) }}
                    </v-list-item-title>
                  </v-badge>
                  <v-badge
                    v-else-if="child.title === 'backend.sidebar.reportDemands'"
                    :content="unreadReportDemandsCount"
                    :value="unreadReportDemandsPresent"
                    inline
                    color="info"
                  >
                    <v-list-item-title>
                      {{ $t(child.title) }}
                    </v-list-item-title>
                  </v-badge>
                  <v-badge
                    v-else-if="
                      child.title === 'backend.sidebar.reportInterpretations'
                    "
                    :content="reportInterpretationsCount"
                    :value="reportInterpretationsPresent"
                    inline
                    color="info"
                  >
                    <v-list-item-title>
                      {{ $t(child.title) }}
                    </v-list-item-title>
                  </v-badge>
                  <v-list-item-title v-else>
                    {{ $t(child.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-item
            v-else-if="!navItem.gate || $can(navItem.gate)"
            :key="navItem.path"
            :to="navItem.path"
            link
            :exact="navItem.exact"
          >
            <v-list-item-icon>
              <v-icon>{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-badge
                v-if="navItem.title === 'backend.sidebar.tickets'"
                :content="openTicketsCount"
                :value="openTicketsPresent"
                inline
                color="info"
              >
                <v-list-item-title>
                  {{ $t(navItem.title) }}
                </v-list-item-title>
              </v-badge>
              <v-list-item-title v-else>
                {{ $t(navItem.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div
        v-if="authUser.vorletzterLogin !== null && !mini"
        class="last-login pa-4"
      >
        <span class="caption font-weight-bold white--text">
          {{ $t("backend.sidebar.lastLogin") }}
          {{ formatDate(authUser.vorletzterLogin) }}
        </span>
      </div>
    </v-navigation-drawer>
    <v-main class="bg--light">
      <v-slide-y-transition>
        <BackendMessageAlert v-if="messages.length" />
      </v-slide-y-transition>
      <router-view v-if="ready" />
    </v-main>
    <NotificationsContainer />
    <AutoLogout />
    <v-dialog
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="searchDialog"
      transition="slide-y-transition"
      width="100%"
    >
      <v-card class="pa-5">
        <v-card-title class="pt-0 px-0">
          <v-spacer />
          <v-btn icon @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-text-field
          v-model="search"
          outlined
          :placeholder="
            $hasAnyRole(authUser, 'Arzt')
              ? searchPlaceholderDoctors
              : searchPlaceholder
          "
        >
        </v-text-field>
      </v-card>
    </v-dialog>
    <v-overlay
      v-if="
        (ready === false &&
          (loadingAuthUser ||
            loadingActivityNotifications ||
            loadingTickets ||
            loadingBackendMessages ||
            loadingReports ||
            loadingReportInterpretations ||
            loadingReportDemands)) ||
        (ready === true && loadingLogout)
      "
      color="white"
      opacity="1"
      class="text-center blue-grey--text"
      z-index="99"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <p v-if="loadingAuthUser" class="mt-4">
        {{ $t("general.loading.userdata") }}
      </p>
      <p v-else-if="loadingActivityNotifications" class="mt-4">
        {{ $t("general.loading.activities") }}
      </p>
      <p v-else-if="loadingTickets" class="mt-4">
        {{ $t("general.loading.tickets") }}
      </p>
      <p v-else-if="loadingReports" class="mt-4">
        {{ $t("general.loading.reports") }}
      </p>
      <p v-else-if="loadingReportInterpretations" class="mt-4">
        {{ $t("general.loading.reportInterpretations") }}
      </p>
      <p v-else-if="loadingBackendMessages" class="mt-4">
        {{ $t("general.loading.messages") }}
      </p>
      <p v-else-if="loadingReportDemands" class="mt-4">
        {{ $t("general.loading.reportDemands") }}
      </p>
      <p v-else class="mt-4">{{ $t("general.loading.logout") }}</p>
    </v-overlay>
    <UpdateAvailableSnackbar />
  </v-app>
</template>

<script>
import ApiService from "../services/ApiService";
import { debounce } from "lodash";
import { mapActions } from "vuex";

import NotificationsContainer from "../components/notifications/NotificationsContainer";
import SearchResults from "../components/search/SearchResults";
import { DateTime } from "luxon";
import LocaleSwitcher from "../components/LocaleSwitcher";
import BackendMessageAlert from "../components/BackendMessageAlert";
import AutoLogout from "@/components/AutoLogout";
import ActivitiesContainer from "@/components/activities/ActivitiesContainer";
import UpdateAvailableSnackbar from "@/components/UpdateAvailableSnackbar";

export default {
  name: "BackendLayout",
  data: function () {
    return {
      drawer: true,
      mini: false,
      search: "",
      searchDialog: false,
      navigationItems: [
        {
          title: "backend.sidebar.dashboard",
          icon: "mdi-view-dashboard",
          path: "/backend",
          exact: true,
        },
        {
          title: "backend.sidebar.offices",
          icon: "mdi-office-building-plus",
          path: "/backend/offices",
          exact: true,
          gate: "list offices",
        },
        {
          title: "backend.sidebar.doctors",
          icon: "mdi-account-multiple",
          path: "/backend/users",
          exact: true,
          gate: "list doctors",
        },
        {
          title: "backend.sidebar.iec",
          icon: "mdi-tooltip-text",
          path: null,
          active: false,
          children: [
            {
              title: "backend.sidebar.iec-tool",
              icon: "mdi-tooltip-text",
              path: "/backend/iec",
              exact: true,
            },
            {
              title: "backend.sidebar.iec-wiki",
              icon: "mdi-book-open-variant",
              path: "/backend/iec/wiki",
              exact: true,
              // locale: "de",
            },
          ],
        },
        {
          title: "backend.sidebar.reports",
          icon: "mdi-text-box",
          path: null,
          gate: [
            "list reports",
            "list report demands",
            "list report interpretations",
            "upload reports",
          ],
          children: [
            {
              title: "backend.sidebar.reportsOverview",
              icon: "mdi-table",
              path: `/backend/reports`,
              exact: true,
              gate: "list reports",
            },
            {
              title: "backend.sidebar.reportDemands",
              icon: "mdi-message-question",
              path: `/backend/reports/demands`,
              exact: true,
              gate: "list report demands",
            },
            {
              title: "backend.sidebar.reportInterpretations",
              icon: "mdi-text-box-search",
              path: `/backend/reports/interpretations`,
              exact: true,
              gate: "list report interpretations",
            },
            {
              title: "backend.sidebar.uploadReports",
              icon: "mdi-cloud-upload",
              path: `/backend/reports/upload`,
              exact: true,
              gate: "upload reports",
            },
          ],
        },
        {
          title: "backend.sidebar.messages",
          icon: "mdi-message",
          path: `/backend/messages`,
          exact: false,
          gate: "list backendmessages",
        },
        {
          title: "backend.sidebar.tickets",
          icon: "mdi-ticket",
          path: `/backend/tickets`,
          gate: "list tickets",
        },
        {
          title: "backend.sidebar.workshops",
          icon: "mdi-school",
          path: `/backend/workshops`,
          gate: "list workshops",
        },
        {
          title: "backend.sidebar.guide",
          icon: "mdi-information-box",
          path: `/backend/guide`,
        },
      ],
      channel: null,
      privateChannel: null,
      ready: false,
    };
  },
  components: {
    UpdateAvailableSnackbar,
    ActivitiesContainer,
    AutoLogout,
    BackendMessageAlert,
    LocaleSwitcher,
    SearchResults,
    NotificationsContainer,
  },
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    authUserInitials() {
      return Object.entries(this.authUser).length
        ? this.authUser.vorname.charAt(0).toUpperCase() +
            this.authUser.nachname.charAt(0).toUpperCase()
        : "";
    },
    searchPlaceholder() {
      return this.$t("backend.navbar.searchPlaceholder");
    },
    searchPlaceholderDoctors() {
      return this.$t("backend.navbar.searchPlaceholderDoctors");
    },
    openTickets() {
      return this.$store.getters["Tickets/open"];
    },
    openTicketsCount() {
      return this.$store.getters["Tickets/openCount"];
    },
    openTicketsPresent() {
      return this.openTicketsCount > 0;
    },
    befunde() {
      return this.$store.state.Reports.results;
    },
    reportDemands() {
      return this.$store.state.Reports.demands;
    },
    unreadReportDemandsCount() {
      return this.$store.state.Reports.unreadDemandMessages;
    },
    unreadReportDemandsPresent() {
      return this.unreadReportDemandsCount > 0;
    },
    iecToolSelectionCount() {
      return this.$store.state.Iec.selection.length;
    },
    iecToolSelectionPresent() {
      return this.iecToolSelectionCount > 0;
    },
    messages() {
      return this.$store.state.Messages.userMessages;
    },
    unreadReportsCount() {
      return this.$store.state.Reports.unreadReports;
    },
    unreadReportsPresent() {
      return this.unreadReportsCount > 0;
    },
    reportInterpretationsCount() {
      return this.$store.getters["Reports/interpretationsCount"];
    },
    reportInterpretationsPresent() {
      return this.reportInterpretationsCount > 0;
    },
    showBadgeForReportsGroup() {
      return (
        this.unreadReportDemandsPresent ||
        this.unreadReportsPresent ||
        this.reportInterpretationsPresent
      );
    },
    loadingAuthUser() {
      return this.$store.state.Loading.authUser;
    },
    loadingActivityNotifications() {
      return this.$store.state.Loading.notifications;
    },
    loadingTickets() {
      return this.$store.state.Loading.tickets;
    },
    loadingReports() {
      return this.$store.state.Loading.reports;
    },
    loadingReportDemands() {
      return this.$store.state.Loading.reportDemands;
    },
    loadingReportInterpretations() {
      return this.$store.state.Loading.reportInterpretations;
    },
    loadingBackendMessages() {
      return this.$store.state.Loading.messages;
    },
    loadingLogout() {
      return this.$store.state.Loading.logout;
    },
  },
  async mounted() {
    this.initializeChannel();
    await this.fetchAuthUser().then(async () => {
      if (!this.authUser.twoFactorAuthenticated) {
        await this.$router.push({
          name: "login-two-factor-challenge",
        });
      } else {
        await this.fetchNotifications();
        await this.fetchTickets();
        await this.fetchReports();
        await this.fetchReportInterpretations();
        await this.fetchUserMessages();
        await this.fetchDemands();
        this.ready = true;
      }
    });
  },
  watch: {
    search() {
      this.setSearchQuery(this.search);
      if (this.search === "") {
        this.onResetSearch();
      }
    },
    $route: {
      async handler() {
        await ApiService.fetchUserAbilities().then((res) => {
          this.$ability.update([{ subject: "all", action: res.data.data }]);
        });
      },
      immediate: true,
    },
  },
  methods: {
    toggleDrawer() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini;
      }
    },
    initializeChannel() {
      let title, message;

      this.$echo
        .private(`App.Models.User.${this.authUser.id}`)
        .listen("TicketUpdated", (e) => {
          ApiService.fetchSingleTicket(e.ticket.uuid).then((res) => {
            this.$store.commit("Tickets/SET_SINGLE_TICKET", res.data);
          });
          ApiService.fetchTickets().then((res) => {
            this.$store.commit("Tickets/SET_TICKETS", res.data);
            const notification = {
              type: "success",
              title: "Ticket aktualisiert",
              message: `Das Ticket ${e.ticket.id} wurde aktualisiert.`,
            };
            this.$store.commit("Notifications/ADD", notification);
          });
        })
        .listen("TicketRead", () => {
          ApiService.fetchTickets().then((res) => {
            this.$store.commit("Tickets/SET_TICKETS", res.data);
          });
        })
        // .listen("NewReportDemandMessage", () => {
        //   // this.fetchDemands().then(() => {
        //   //   this.fetchDemandMessages(
        //   //     data.reportDemandMessage.report_demand.uuid
        //   //   );
        //   // });
        //   this.fetchDemands();
        // })
        // .listen("ReportDemandRead", () => {
        //   this.fetchDemands();
        // })
        // .listen("ReportDemandMessageRead", () => {
        //   // this.fetchDemands().then(() => {
        //   //   this.fetchDemandMessages(
        //   //     data.reportDemandMessage.report_demand.uuid
        //   //   );
        //   // });
        //   this.fetchDemands();
        // })
        .listen("ReportDemandMessageDeleted", (data) => {
          this.fetchDemandMessages(data.reportDemand.uuid);
        })
        .listen("ReportInterpretationRead", () => {
          this.fetchReportInterpretations();
        })
        .listen("ReportInterpretationUpdated", () => {
          this.fetchReportInterpretations();
        })
        .notification((notification) => {
          if (notification.type === "App\\Notifications\\ResultUploaded") {
            this.fetchReports();
            title = this.$t("backend.reports.notifications.titles.uploaded");
            message = notification.message;
          }
          if (notification.type === "App\\Notifications\\ResultRead") {
            this.fetchReports();
            title = this.$t("backend.reports.notifications.titles.opened");
            message = notification.message;
          }
          if (notification.type === "App\\Notifications\\ResultDeleted") {
            this.fetchReports();
            title = this.$t("backend.reports.notifications.titles.deleted");
            message = notification.message;
          }
          if (
            notification.type ===
            "App\\Notifications\\NewReportInterpretationRequest"
          ) {
            this.fetchReportInterpretations();
            title = "Neue Anfrage";
            message = notification.message;
          }
          if (
            notification.type === "App\\Notifications\\NewTicket" ||
            notification.type === "App\\Notifications\\NewTicketMail"
          ) {
            this.fetchTickets();
            title = "Neues Ticket erhalten";
            message = notification.ticket.subject;
          }
          if (notification.type === "App\\Notifications\\NewTicketComment") {
            this.fetchSingleTicket(notification.ticket.uuid);
            title = "Neuer Kommentar";
            message = notification.message;
          }
          if (notification.type === "App\\Notifications\\NewReportDemand") {
            this.fetchDemands();
            title = "Neue Befundnachfrage";
            message = notification.message;
          }
          if (notification.type === "App\\Notifications\\PostMoved") {
            title = this.$t("backend.forum.notifications.titles.postMoved");
            message = notification.message;
          }

          const notificationToAdd = {
            type: "success",
            title: title,
            message: message,
            timeout: 10000,
          };

          this.$store.dispatch("Notifications/add", notificationToAdd);

          this.fetchNotifications();
        });
      this.$echo
        .join("Backend.Messages")
        .listen("NewBackendMessage", () => {
          ApiService.fetchMessagesForUser().then((res) => {
            this.$store.commit("Messages/SET_USER_MESSAGES", res.data);
          });
        })
        .listen("UpdateBackendMessage", (e) => {
          ApiService.fetchMessagesForUser().then((res) => {
            this.$store.commit("Messages/SET_USER_MESSAGES", res.data);
          });
          ApiService.fetchSingleMessage(e.backendMessage.id).then((res) => {
            this.$store.commit("Messages/SET_SINGLEMESSAGE", res.data.data);
          });
        })
        .listen("ConfirmBackendMessage", (e) => {
          ApiService.fetchSingleMessage(e.backendMessage.id).then((res) => {
            this.$store.commit("Messages/SET_SINGLEMESSAGE", res.data.data);
          });
        })
        .listen("HideBackendMessage", () => {
          ApiService.fetchMessagesForUser().then((res) => {
            this.$store.commit("Messages/SET_USER_MESSAGES", res.data);
          });
        })
        .listen("DeleteBackendMessage", () => {
          ApiService.fetchMessagesForUser().then((res) => {
            this.$store.commit("Messages/SET_USER_MESSAGES", res.data);
          });
        });
    },
    sendSearchQuery: debounce(function (search) {
      const data = {
        search,
      };

      if (search && search.length > 1) {
        this.showResults(true);
        this.fetchResults(data);
      } else {
        this.resetSearch();
      }
    }, 1000),
    onResetSearch() {
      if (this.search !== "") {
        this.search = "";
        this.resetSearch();
      }
    },
    onLogout() {
      this.$store.dispatch("Loading/logout", true);
      ApiService.logout().then(async () => {
        await this.$router.replace("/login").then(() => {
          this.$store.dispatch("User/resetState");
          this.$store.dispatch("Search/resetSearch");
          this.$store.dispatch("ActivityNotifications/resetState");
          this.$store.dispatch("Iec/resetSelection");
        });
      });
    },
    checkGates(gates) {
      if (!gates) {
        return true;
      } else {
        return gates.some((gate) => this.$can(gate));
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date)
        .setLocale(this.$i18n.locale)
        .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    ...mapActions("User", ["fetchAuthUser"]),
    ...mapActions("Search", [
      "setSearchQuery",
      "fetchResults",
      "showResults",
      "resetSearch",
    ]),
    ...mapActions("ActivityNotifications", [
      "fetchNotifications",
      "markAsRead",
      "deleteSingle",
      "markAllAsRead",
      "deleteAll",
    ]),
    ...mapActions("Reports", [
      "fetchReports",
      "fetchDemands",
      "fetchDemandMessages",
      "fetchReportInterpretations",
    ]),
    ...mapActions("Tickets", ["fetchTickets", "fetchSingleTicket"]),
    ...mapActions("Messages", ["fetchUserMessages"]),
  },
  beforeRouteUpdate(to, from, next) {
    this.onResetSearch();
    next();
  },
};
</script>

<style scoped>
.bg--light {
  background-color: #f0f2f5;
}
.searchbar {
  position: relative;
}
.searchresults {
  position: absolute;
  top: 40px;
  right: 0;
  bottom: auto;
  left: 0;
}
.last-login {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
