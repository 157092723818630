<template>
  <div class="backend-message-container">
    <v-banner
      v-for="message in messages"
      :key="message.id"
      app
      color="white"
      two-line
      transition="slide-y-transition"
      width="100%"
      class="ma-0"
    >
      <template v-slot:icon>
        <v-avatar color="info" size="48">
          <v-icon color="white">mdi-information</v-icon>
        </v-avatar>
      </template>
      <h3 class="text-h5 mb-4">{{ message.title }}</h3>
      <p>
        {{ message.text }}
      </p>
      <template v-slot:actions>
        <v-btn
          color="info"
          :disabled="confirming"
          :loading="confirming"
          outlined
          @click="confirmMessage(message)"
          >{{ $t("backend.messageAlert.buttonAcknowledged") }}</v-btn
        >
      </template>
      <template v-if="message.files && message.files.length">
        <v-divider class="my-5 info" style="opacity: 0.3" />
        <h3 class="text-h5 mb-3">
          {{ $t("backend.messageAlert.attachments") }}
        </h3>
        <v-btn
          v-for="file in message.files"
          :key="file.name"
          class="mr-2"
          color="info"
          outlined
          @click="showFile(file)"
        >
          {{ file.original_name }}
          <v-progress-circular
            v-if="loadingFile && fileId === file.id"
            class="ml-2"
            size="18"
            indeterminate
          />
        </v-btn>
      </template>
    </v-banner>
  </div>
</template>

<script>
import ApiService from "../services/ApiService";

export default {
  name: "BackendMessageAlert",
  data: () => ({
    loadingFile: false,
    fileId: null,
    confirming: false,
  }),
  computed: {
    messages() {
      return this.$store.state.Messages.userMessages;
    },
  },
  methods: {
    confirmMessage(message) {
      this.confirming = true;
      ApiService.readMessage(message.id)
        .then((res) => {
          this.$store.commit("Messages/SET_USER_MESSAGES", res.data);
        })
        .finally(() => {
          this.confirming = false;
        });
    },
    async showFile(file) {
      this.fileId = file.id;
      this.loadingFile = true;
      await ApiService.showFile(file.id)
        .then((res) => {
          let a = document.createElement("a");
          a.href = res.data.url;
          a.download = file.original_name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch()
        .finally(() => {
          this.loadingFile = false;
          this.fileId = null;
        });
    },
  },
};
</script>

<style scoped></style>
