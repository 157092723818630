export const namespaced = true;

export const state = {
  selection: [],
};

export const mutations = {
  ADD_TO_SELECTION(state, data) {
    // Push the substance to the selected array if it's not already in there
    if (
      state.selection.findIndex((substance) => substance.id === data.id) === -1
    ) {
      state.selection.push(data);
    }
  },
  REMOVE_FROM_SELECTION(state, substanceToRemove) {
    const index = state.selection.findIndex(
      (substance) => substance.id === substanceToRemove.id
    );
    state.selection.splice(index, 1);
  },
  RESET_SELECTION(state) {
    state.selection = [];
  },
};

export const actions = {
  addSubstanceToSelection({ commit }, data) {
    commit("ADD_TO_SELECTION", data);
  },
  removeSubstanceFromSelection({ commit }, substanceToRemove) {
    commit("REMOVE_FROM_SELECTION", substanceToRemove);
  },
  resetSelection({ commit }) {
    commit("RESET_SELECTION");
  },
};
