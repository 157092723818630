<template>
  <v-container>
    <h2 class="mb-5">
      {{ begruessung }}{{ anrede }}{{ fullname }}
      <!--      <span v-if="$hasAnyRole(authUser, 'Arzt')"-->
      <!--        >schön Sie wiederzusehen!</span-->
      <!--      >-->
      <!--      <span v-else>schön, dass du wieder da bist.</span>-->
    </h2>
    <DashboardArzt
      v-if="$hasAnyRole(authUser, ['Admin', 'Arzt'])"
      :user="authUser"
    ></DashboardArzt>
    <DashboardIgl v-else></DashboardIgl>
  </v-container>
</template>

<script>
import DashboardIgl from "@/components/dashboards/DashboardIgl";
import DashboardArzt from "@/components/dashboards/DashboardArzt";
import { DateTime } from "luxon";
export default {
  name: "Dashboard",
  components: { DashboardArzt, DashboardIgl },
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    begruessung() {
      const stunde = DateTime.now().hour;

      if (stunde < 11) {
        return this.$i18n.locale === "de" ? "Guten Morgen " : "Good morning ";
      }
      if (stunde >= 11 && stunde < 18) {
        return this.$i18n.locale === "de" ? "Moin " : "Hello ";
      }
      if (stunde >= 18) {
        return this.$i18n.locale === "de" ? "Guten Abend " : "Good evening ";
      }
      return "";
    },
    anrede() {
      if (this.authUser.anrede && this.$hasAnyRole(this.authUser, "Arzt")) {
        if (this.authUser.anrede.search("Herr") !== -1) {
          return this.$i18n.locale === "de" ? "Herr " : "Mr. ";
        } else if (this.authUser.anrede.search("Frau") !== -1) {
          return this.$i18n.locale === "de" ? "Frau " : "Ms. ";
        }
      }
      return "";
    },
    fullname() {
      if (this.$hasAnyRole(this.authUser, "Arzt")) {
        return this.authUser
          ? `${this.authUser.titel ? this.authUser.titel + " " : ""}${
              this.authUser.nachname
            }`
          : "";
      } else {
        return `${this.authUser.vorname}`;
      }
    },
  },
};
</script>

<style scoped></style>
