<template>
  <div class="twofa">
    <v-card outlined class="pa-5">
      <v-card-title class="pt-0 px-0">
        {{ $t("frontend.pages.firstRun.twoFactorAuth.title") }}
      </v-card-title>
      <v-chip-group v-model="twoFactorType" color="primary" class="mb-4">
        <v-chip
          value="mail"
          large
          color="primary"
          :disabled="
            authUser.twoFactorConfirmedAt !== null &&
            authUser.twoFactorType === 'app'
          "
          filter
          outlined
          >E-Mail</v-chip
        >
        <v-chip
          value="app"
          large
          color="primary"
          :disabled="
            authUser.twoFactorConfirmedAt !== null &&
            authUser.twoFactorType === 'mail'
          "
          filter
          outlined
          >App</v-chip
        >
      </v-chip-group>

      <v-slide-y-transition>
        <v-alert
          v-if="authUser.twoFactorConfirmedAt"
          text
          dense
          prominent
          type="success"
        >
          {{ $t("frontend.pages.firstRun.twoFactorAuth.messages.active") }}
        </v-alert>
        <v-alert
          v-else-if="!authUser.twoFactorSecret"
          text
          dense
          prominent
          type="error"
        >
          {{ $t("frontend.pages.firstRun.twoFactorAuth.messages.inactive") }}
        </v-alert>
        <v-alert v-else text dense prominent type="warning">
          <span v-if="authUser.twoFactorType === 'mail'">
            {{ $t("frontend.pages.firstRun.twoFactorAuth.messages.typeMail") }}
          </span>
          <span v-else>
            {{ $t("frontend.pages.firstRun.twoFactorAuth.messages.typeApp") }}
          </span>
        </v-alert>
      </v-slide-y-transition>
      <template
        v-if="authUser.twoFactorSecret && !authUser.twoFactorConfirmedAt"
      >
        <div class="mb-4 text-center" v-html="qrCodeHtml"></div>
        <v-form>
          <v-otp-input
            ref="formFieldOtp"
            v-model="twoFactorAuthCode"
            :disabled="loadingTwoFactorConfirmation"
            autofocus
            @finish="confirmTwoFactorAuthentication(twoFactorAuthCode)"
          >
          </v-otp-input>
        </v-form>
        <a v-if="twoFactorType === 'mail'" href="#" @click.prevent="resendMail"
          >{{ $t("frontend.pages.firstRun.twoFactorAuth.noMail") }}
        </a>
      </template>

      <v-card-actions v-if="!authUser.twoFactorConfirmedAt" class="pb-0 px-0">
        <v-spacer />
        <v-btn class="mr-2" depressed large @click="logout">{{
          $t("general.buttons.cancel")
        }}</v-btn>
        <v-btn
          depressed
          :disabled="!twoFactorType"
          large
          color="primary"
          @click="dialogPasswordConfirmation = true"
          >{{
            $t("frontend.pages.firstRun.twoFactorAuth.buttonActivate")
          }}</v-btn
        >
      </v-card-actions>
      <v-overlay
        v-if="
          loadingTwoFactorAuthenticationEnable ||
          loadingQrCode ||
          loadingTwoFactorConfirmation ||
          loadingPasswordConfirmation ||
          resending
        "
        absolute
        color="white"
        opacity="0.9"
        class="text-center blue-grey--text"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        <p v-if="loadingPasswordConfirmation" class="mt-4">
          {{
            $t(
              "frontend.pages.firstRun.twoFactorAuth.messages.confirmingPassword"
            )
          }}
        </p>
        <p v-else-if="loadingTwoFactorAuthenticationEnable" class="mt-4">
          {{ $t("frontend.pages.firstRun.twoFactorAuth.messages.activating") }}
        </p>
        <p v-else-if="loadingQrCode" class="mt-4">
          {{
            $t("frontend.pages.firstRun.twoFactorAuth.messages.loadingQrCode")
          }}
        </p>
        <p v-else-if="resending" class="mt-4">
          {{ $t("frontend.pages.firstRun.twoFactorAuth.messages.resending") }}
        </p>
        <p v-else-if="loadingTwoFactorConfirmation" class="mt-4">
          {{ $t("frontend.pages.firstRun.twoFactorAuth.messages.confirming") }}
        </p>
      </v-overlay>
    </v-card>
    <v-dialog
      v-model="dialogPasswordConfirmation"
      persistent
      max-width="800"
      width="100%"
    >
      <v-card outlined class="pa-5">
        <v-progress-linear
          v-if="loadingPasswordConfirmation"
          absolute
          top
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-title class="pt-0 px-0">
          {{ $t("frontend.pages.firstRun.twoFactorAuth.safetyStop") }}
          <v-spacer />
          <v-btn icon @click="dialogPasswordConfirmation = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <p>{{ $t("frontend.pages.firstRun.twoFactorAuth.currentPassword") }}</p>
        <v-text-field
          v-model="passwordConfirm"
          :label="$t('frontend.pages.firstRun.credentials.labels.password')"
          :type="passwordConfirmVisible ? 'text' : 'password'"
          outlined
          :append-icon="passwordConfirmVisible ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="passwordConfirmVisible = !passwordConfirmVisible"
          @keydown.enter="confirmPassword(passwordConfirm)"
        ></v-text-field>
        <v-card-actions class="pb-0 px-0">
          <v-spacer />
          <v-btn
            large
            depressed
            :disabled="
              passwordConfirm.length === 0 || loadingPasswordConfirmation
            "
            color="primary"
            @click="confirmPassword(passwordConfirm)"
            >{{ $t("general.buttons.send") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TwoFactorAuthenticationRecoveryCodesDialog
      :value="dialogRecoveryCodes"
      :recovery-codes="recoveryCodes"
      first-run
      @dialog:close="dialogRecoveryCodes = false"
    />
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { mapActions } from "vuex";
import { getError } from "@/util/helpers";
import TwoFactorAuthenticationRecoveryCodesDialog from "@/components/dialogs/TwoFactorAuthenticationRecoveryCodesDialog";

export default {
  name: "FirstLoginTwoFactorAuth",
  components: { TwoFactorAuthenticationRecoveryCodesDialog },
  data: () => ({
    dialogPasswordConfirmation: false,
    dialogRecoveryCodes: false,
    passwordConfirm: "",
    passwordConfirmVisible: false,
    twoFactorAuthCode: "",
    twoFactorType: null,
    twoFactorQrCodeAvailable: false,
    twoFactorAuthConfirmed: false,
    qrCodeHtml: "",
    recoveryCodesConfirmed: false,
    recoveryCodes: [],
    loadingPasswordConfirmation: false,
    loadingTwoFactorAuthenticationEnable: false,
    loadingTwoFactorAuthenticationDisable: false,
    loadingQrCode: false,
    loadingTwoFactorConfirmation: false,
    loadingRecoveryCodes: false,
    resending: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    rules() {
      return {
        required: (value) =>
          !!value ||
          this.$t("frontend.pages.firstRun.twoFactorAuth.rules.required"),
      };
    },
  },
  methods: {
    confirmPassword(password) {
      this.loadingPasswordConfirmation = true;
      ApiService.confirmPassword({ password })
        .then(() => {
          this.dialogPasswordConfirmation = false;
          this.twoFactorAuthCode = "";
          if (
            this.authUser.twoFactorSecret &&
            this.authUser.twoFactorConfirmedAt
          ) {
            this.disableTwoFactorAuthentication();
          } else {
            this.enableTwoFactorAuthentication();
          }
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.error"
            ),
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingPasswordConfirmation = false;
        });
    },
    enableTwoFactorAuthentication() {
      this.loadingTwoFactorAuthenticationEnable = true;
      ApiService.enableTwoFactorAuth({ type: this.twoFactorType })
        .then(async () => {
          if (this.twoFactorType === "app") {
            this.fetchTwoFactorQrCodeSvg();
          }
          await this.fetchAuthUser().then(() => {
            this.loadingTwoFactorAuthenticationEnable = false;
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.error"
            ),
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingTwoFactorAuthenticationEnable = false;
        });
    },
    fetchTwoFactorQrCodeSvg() {
      this.loadingQrCode = true;
      ApiService.fetchTwoFactorQrCodeSvg()
        .then((res) => {
          this.twoFactorQrCodeAvailable = true;
          this.qrCodeHtml = res.data.svg;
          this.fetchAuthUser().then(() => {
            this.loadingQrCode = false;
            this.$refs.formFieldCode.focus();
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.error"
            ),
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        });
    },
    confirmTwoFactorAuthentication(code, type) {
      this.loadingTwoFactorConfirmation = true;
      ApiService.confirmTwoFactorAuth({ code, type })
        .then(() => {
          const notification = {
            type: "success",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.done"
            ),
            message: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.messages.activated"
            ),
          };
          this.$store.dispatch("Notifications/add", notification);
          this.twoFactorAuthConfirmed = true;

          if (this.twoFactorType === "app") {
            this.fetchTwoFactorRecoveryCodes();
          } else {
            this.nextStep();
          }
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.error"
            ),
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingTwoFactorConfirmation = false;
        });
    },
    async fetchTwoFactorRecoveryCodes() {
      this.loadingRecoveryCodes = true;

      await ApiService.fetchTwoFactorRecoveryCodes()
        .then((res) => {
          this.recoveryCodes = res.data;
          this.dialogRecoveryCodes = true;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.error"
            ),
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingRecoveryCodes = false;
        });
    },
    async resendMail() {
      this.resending = true;

      await ApiService.resendTwoFactorAuthMail()
        .then(() => {
          const notification = {
            type: "success",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.done"
            ),
            message: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.messages.mailSent"
            ),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: this.$t(
              "frontend.pages.firstRun.twoFactorAuth.notifications.titles.error"
            ),
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.resending = false;
        });
    },
    async nextStep() {
      this.dialogRecoveryCodes = false;
      await this.fetchAuthUser().then(() => {
        this.$router.push({ name: "first-run-newsletter" });
      });
    },
    logout() {
      ApiService.logout().then(() => {
        this.$store.dispatch("User/resetState");
        this.$router.push("/login");
      });
    },
    ...mapActions("User", ["fetchAuthUser"]),
  },
};
</script>

<style scoped></style>
