import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export const namespaced = true;

export const state = {
  tickets: [],
  recentTickets: [],
  singleTicket: {},
  singleAnswer: {},
  categories: [],
  subCategories: [
    { name: "Allgemein", value: "Allgemein" },
    { name: "Rechnung", value: "Rechnung" },
  ],
  statuses: [],
  priorities: [],
  showDialog: false,
  dialogType: "new",
};

export const mutations = {
  SET_TICKETS(state, tickets) {
    state.tickets = tickets;
  },
  SET_RECENT_TICKETS(state, tickets) {
    state.recentTickets = tickets;
  },
  SET_SINGLE_TICKET(state, data) {
    state.singleTicket = data;
  },
  SET_SINGLE_ANSWER(state, data) {
    state.singleAnswer = data;
  },
  SET_CATEGORIES(state, data) {
    state.categories = data;
  },
  SET_STATUSES(state, data) {
    state.statuses = data;
  },
  SET_PRIORITIES(state, data) {
    state.priorities = data;
  },
  SET_SHOW_DIALOG(state, data) {
    state.showDialog = data;
  },
  SET_DIALOG_TYPE(state, data) {
    state.dialogType = data;
  },
};

export const actions = {
  async fetchTickets({ commit, dispatch }) {
    dispatch("Loading/tickets", true, { root: true });
    await ApiService.fetchTickets()
      .then((res) => {
        commit("SET_TICKETS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/tickets", false, { root: true });
      });
  },
  async fetchRecentTickets({ commit, dispatch }, data) {
    dispatch("Loading/ticketsRecent", true, { root: true });
    await ApiService.fetchRecentTickets(data)
      .then((res) => {
        commit("SET_RECENT_TICKETS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/ticketsRecent", false, { root: true });
      });
  },
  async fetchSingleTicket({ commit }, uuid) {
    // dispatch("Loading/ticketSingle", true, { root: true });
    await ApiService.fetchSingleTicket(uuid)
      .then((res) => {
        commit("SET_SINGLE_TICKET", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        // dispatch("Loading/ticketSingle", false, { root: true });
      });
  },
  async fetchTicketCategories({ commit, dispatch }) {
    dispatch("Loading/ticketCategories", true, { root: true });
    await ApiService.fetchTicketCategories()
      .then((res) => {
        commit("SET_CATEGORIES", res.data);
      })
      .finally(() => {
        dispatch("Loading/ticketCategories", false, { root: true });
      });
  },
  async fetchTicketPriorities({ commit, dispatch }) {
    dispatch("Loading/ticketPriorities", true, { root: true });
    await ApiService.fetchTicketPriorities()
      .then((res) => {
        commit("SET_PRIORITIES", res.data);
      })
      .finally(() => {
        dispatch("Loading/ticketPriorities", false, { root: true });
      });
  },
  async fetchTicketStatuses({ commit, dispatch }) {
    dispatch("Loading/ticketStatuses", true, { root: true });
    await ApiService.fetchTicketStatuses()
      .then((res) => {
        commit("SET_STATUSES", res.data);
      })
      .finally(() => {
        dispatch("Loading/ticketStatuses", false, { root: true });
      });
  },
  async storeTicket({ commit, dispatch }, data) {
    dispatch("Loading/ticketStore", true, { root: true });
    await ApiService.storeTicket(data)
      .then(() => {
        commit("SET_TICKET", data);
        dispatch("fetchTickets");

        const notification = {
          type: "success",
          title: "Erfolg",
          message: "Das Ticket wurde erstellt.",
        };

        dispatch("Notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/ticketStore", false, { root: true });
      });
  },
  async updateTicket({ commit, dispatch }, { ticket, data }) {
    dispatch("Loading/ticketStore", true, { root: true });
    await ApiService.updateTicket(ticket, data)
      .then((res) => {
        commit("SET_SINGLE_TICKET", res.data);
        commit("SET_SHOW_DIALOG", false);
        dispatch("fetchTickets");

        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Ticket erfolgreich aktualisiert.",
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/ticketStore", false, { root: true });
      });
  },
  readTicket({ commit }, data) {
    ApiService.readTicket(data)
      .then((res) => {
        commit("SET_TICKETS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      });
  },
  async answerTicket({ commit, dispatch }, data) {
    dispatch("Loading/ticketAnswer", true, { root: true });
    await ApiService.answerTicket(data)
      .then((res) => {
        commit("SET_SINGLE_TICKET", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/ticketAnswer", false, { root: true });
      });
  },
  async updateAnswer({ dispatch, commit }, data) {
    dispatch("Loading/ticketAnswer", true, { root: true });
    await ApiService.updateTicketAnswer(data)
      .then((res) => {
        commit("SET_SINGLE_TICKET", res.data);
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Antwort aktualisiert.",
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/ticketAnswer", false, { root: true });
      });
  },
  readAnswer({ commit }, ticketUuid) {
    ApiService.readTicketAnswer(ticketUuid)
      .then((res) => {
        commit("SET_TICKETS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      });
  },
  deleteAnswer({ commit, dispatch }, data) {
    dispatch("Loading/ticketAnswer", true, { root: true });
    ApiService.deleteTicketAnswer(data)
      .then((res) => {
        commit("SET_SINGLE_TICKET", res.data);
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Antwort gelöscht.",
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/ticketAnswer", false, { root: true });
      });
  },
};

export const getters = {
  open: (state) => {
    return state.tickets.length > 0
      ? state.tickets.filter((ticket) => ticket.status.id !== 4)
      : [];
  },
  openCount: (state, getters) => {
    return getters.open.length;
  },
  closed: (state) => {
    return state.tickets.length > 0
      ? state.tickets.filter((ticket) => ticket.status.id === 4)
      : [];
  },
  closedCount: (state, getters) => {
    return getters.closed.length;
  },
};
