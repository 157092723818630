import { apiClient } from "@/services/ApiClient";

export default {
  // Auth START
  async login(credentials) {
    await apiClient.get("/sanctum/csrf-cookie");
    return apiClient.post("/login", credentials);
  },
  logout() {
    return apiClient.post("/logout");
  },
  fetchAuthUser() {
    return apiClient.get("/api/auth/user");
  },
  verifyEmail(url) {
    return apiClient.get(url);
  },
  sendEmailVerificationNotification(user) {
    return apiClient.post("/api/email/verification-notification", user);
  },
  async requestCsrfCookie() {
    return await apiClient.get("/sanctum/csrf-cookie");
  },
  createApiToken(data, uuid) {
    return apiClient.post(`/api/tokens/create/${uuid}`, data);
  },
  authBroadcasting(data) {
    return apiClient.post("/broadcasting/auth", data);
  },
  forgotPasswordBackend(username) {
    return apiClient.post("/api/forgot-password", username);
  },
  forgotPasswordFrontend(username) {
    return apiClient.post("/forgot-password", username);
  },
  resetPassword(data) {
    return apiClient.post("/reset-password", data);
  },
  updatePasswordAfterFirstLogin(payload) {
    return apiClient.put("/api/auth/user/new-password", payload);
  },
  newsletterFetchRecipients() {
    return apiClient.get("/api/newsletter/recipients");
  },
  newsletterAddRecipient(data) {
    return apiClient.post("/api/newsletter/recipients", data);
  },
  newsletterFetchRecipient(uuid) {
    return apiClient.get(`/api/newsletter/recipients/${uuid}`);
  },
  newsletterDeleteRecipient(recipientId) {
    return apiClient.delete(`/api/newsletter/recipients/${recipientId}`);
  },
  // Auth END
  // -----------------------------
  // Users START
  fetchUsers(sortingData) {
    return apiClient.get("/api/users", sortingData);
  },
  async fetchMitarbeiter(sortingData) {
    return apiClient.get(
      this.paginationSortingUrl("/api/users/employees", sortingData)
    );
  },
  showSingleUser(id) {
    return apiClient.get(`/api/users/${id}`);
  },
  createNewUser(data) {
    return apiClient.post("/api/users", data);
  },
  updateUser(data, id) {
    return apiClient.put(`/api/users/${id}`, data);
  },
  updateLanguage(data, uuid) {
    return apiClient.put(`/api/users/${uuid}/update-language`, data);
  },
  updateLock(data, uuid) {
    return apiClient.put(`/api/users/${uuid}/update-lock`, data);
  },
  updateProfileInformation(data) {
    return apiClient.put("/api/user/profile-information", data);
  },
  deleteUser(id) {
    return apiClient.delete(`/api/users/${id}`);
  },
  paginate(url) {
    return apiClient.get(url);
  },
  inviteAllUsers() {
    return apiClient.post("/api/users/invite-all");
  },
  markAsOnline(userId) {
    return apiClient.post("/api/users/online", userId);
  },
  resetAccount(uuid) {
    return apiClient.post(`/api/users/${uuid}/reset`);
  },
  // Users END
  // -----------------------------
  // Praxen START
  fetchPraxen(config = null) {
    return apiClient.get("/api/praxen", config);
  },
  fetchPraxis(id) {
    return apiClient.get(`/api/praxen/${id}`);
  },
  // Praxen END
  // -----------------------------
  // IEC START
  fetchSubstances(config) {
    return apiClient.get("/api/iec/substances", config);
  },
  storeNewSubstance(data) {
    return apiClient.post("/api/iec/substances", data);
  },
  updateSubstance(data) {
    return apiClient.put(`/api/iec/substances/${data.id}`, data);
  },
  fetchSubstanceGroups() {
    return apiClient.get("/api/iec/substance-groups");
  },
  storeNewSubstanceGroup(data) {
    return apiClient.post("/api/iec/substance-groups", data);
  },
  updateSubstanceGroup(data) {
    return apiClient.put(`/api/iec/substance-groups/${data.id}`, {
      title: data.title,
      substances: data.data,
    });
  },
  async goToPaginationPage(url) {
    return await apiClient.get(url);
  },
  download(selectionData, config) {
    return apiClient.post("/api/iec/results/generate", selectionData, config);
  },
  downloadExcel(selectionData, config) {
    return apiClient.post(
      "/api/iec/results/generate/excel",
      selectionData,
      config
    );
  },
  // IEC END
  // IEC WIKI START
  fetchWikiArticles(sortingData) {
    return apiClient.get("/api/wiki/iec", sortingData);
  },
  fetchWikiArticle(id) {
    return apiClient.get(`/api/wiki/iec/${id}`);
  },
  storeWikiArticle(data) {
    return apiClient.post("/api/wiki/iec", data);
  },
  updateWikiArticle(data) {
    return apiClient.put(`/api/wiki/iec/${data.id}`, data);
  },
  generateDescriptionsPdf(data) {
    return apiClient.post("/api/wiki/iec/definitions", data);
  },
  deleteWikiArticle(article) {
    return apiClient.delete(`/api/wiki/iec/${article.id}`);
  },
  // IEC WIKI END
  // -----------------------------
  // Befunde START
  fetchReports(sortingData) {
    return apiClient.get(
      this.paginationSortingUrl("/api/befunde", sortingData)
    );
  },
  fetchReportsByUser(sortingData, id) {
    return apiClient.get(
      this.paginationSortingUrl(`/api/befunde/user/${id}`, sortingData)
    );
  },
  uploadReport(data, config) {
    return apiClient.post("/api/befunde", data, config);
  },
  showReport(id, config) {
    return apiClient.get(`/api/befunde/${id}`, config);
  },
  filterReportsByArzt(data) {
    return apiClient.post("/api/befunde/filter", data);
  },
  deleteReport(id) {
    return apiClient.delete(`/api/befunde/${id}`);
  },
  fetchReportInterpretations() {
    return apiClient.get("/api/finding-interpretations");
  },
  fetchSingleReportInterpretation(id) {
    return apiClient.get(`/api/finding-interpretations/${id}`);
  },
  sendReportInterpretationRequestMail(data) {
    return apiClient.post("/api/finding-interpretations/request", data);
  },
  storeReportInterpretation(data) {
    return apiClient.post("/api/finding-interpretations", data);
  },
  updateReportInterpretation(data) {
    return apiClient.put(`/api/finding-interpretations/${data.id}`, data);
  },
  deleteReportInterpretation(id) {
    return apiClient.delete(`/api/finding-interpretations/${id}`);
  },
  fetchReportDemands(query) {
    const baseUrl = "/api/reports/demands";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchArchivedReportDemands() {
    return apiClient.get("/api/reports/demands?archived=true");
  },
  fetchReportDemandMessages(demandUuid, query) {
    const baseUrl = `/api/reports/demands/${demandUuid}/messages`;
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  storeReportDemand(data) {
    return apiClient.post("/api/reports/demands", data);
  },
  storeReportDemandMessage(data) {
    return apiClient.post("/api/reports/demand-messages", data);
  },
  deleteReportDemandMessage(uuid) {
    return apiClient.delete(`/api/reports/demand-messages/${uuid}`);
  },
  readReportDemand(demandUuid) {
    return apiClient.put(`/api/reports/demands/${demandUuid}/read`);
  },
  readReportDemandMessage(data) {
    return apiClient.put(
      `/api/reports/demand-messages/${data.messageUuid}`,
      data
    );
  },
  createTicketFromReportDemand(demandUuid) {
    return apiClient.post(`/api/reports/demands/${demandUuid}/ticket`);
  },
  archiveReportDemand(demandUuid) {
    return apiClient.put(`/api/reports/demands/${demandUuid}/archive`);
  },
  getAzureAccessToken() {
    return apiClient.get("/api/auth/oauth");
    // return apiClient.post(
    //   `https://login.microsoftonline.com/18a91c07-4012-41ed-adcf-5d4396b83f87/oauth2/v2.0/token`,
    //   data,
    //   {
    //     withCredentials: false,
    //   }
    // );
  },
  getAzureEvents() {
    return apiClient.get("/api/events/finding-interpretations");
  },
  // Befunde END
  // -----------------------------
  // Search START
  search(search) {
    return apiClient.post("/api/search", search);
  },
  searchWiki(search) {
    return apiClient.post("/api/search/wiki", search);
  },
  searchIecSubstances(search) {
    return apiClient.post("/api/search/iec", search);
  },
  // Search END
  // -----------------------------
  // Notifications START
  async fetchNotifications() {
    return apiClient.get("/api/notifications");
  },
  markAllAsRead() {
    return apiClient.get("/api/notifications/read");
  },
  deleteAll() {
    return apiClient.get("/api/notifications/delete");
  },
  markAsRead(id) {
    return apiClient.post(`/api/notifications/${id}/read`, id);
  },
  delete(id) {
    return apiClient.post(`/api/notifications/${id}/delete`, id);
  },
  // Notifications END
  // -----------------------------
  // Security START
  updatePassword(password) {
    return apiClient.put("/api/user/password", password);
  },
  confirmPassword(password) {
    return apiClient.post("/api/user/confirm-password", password);
  },
  enableTwoFactorAuth(type) {
    return apiClient.post("/api/user/two-factor-authentication", type);
  },
  resendTwoFactorAuthMail() {
    return apiClient.post("/api/user/resend-two-factor-authentication-code");
  },
  disableTwoFactorAuth() {
    return apiClient.delete("/api/user/two-factor-authentication");
  },
  confirmTwoFactorAuth(code) {
    return apiClient.post(
      "/api/user/confirmed-two-factor-authentication",
      code
    );
  },
  fetchTwoFactorQrCodeSvg() {
    return apiClient.get("/api/user/two-factor-qr-code");
  },
  fetchTwoFactorRecoveryCodes() {
    return apiClient.get("/api/user/two-factor-recovery-codes");
  },
  regenerateTwoFactorRecoveryCodes() {
    return apiClient.post("/api/user/two-factor-recovery-codes");
  },
  twoFactorChallenge(data) {
    return apiClient.post("/api/two-factor-challenge", data);
    // if (data.type === "app") {
    //   return apiClient.post("/api/two-factor-challenge", data);
    // } else {
    //   return apiClient.post("/api/two-factor-request", data);
    // }
  },
  // Security END
  // -----------------------------
  // Tickets START
  fetchTickets(query) {
    const baseUrl = "/api/tickets";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  fetchRecentTickets(data) {
    return apiClient.post("/api/tickets/recent", data);
  },
  fetchSingleTicket(uuid) {
    return apiClient.get(`/api/tickets/${uuid}`);
  },
  fetchTicketCategories() {
    return apiClient.get("/api/tickets/categories");
  },
  fetchTicketStatuses() {
    return apiClient.get(`/api/tickets/statuses`);
  },
  fetchTicketPriorities() {
    return apiClient.get(`/api/tickets/priorities`);
  },
  storeTicket(data) {
    return apiClient.post(`/api/tickets`, data);
  },
  updateTicket(ticket, data) {
    return apiClient.put(`/api/tickets/${ticket}`, data);
  },
  readTicket(data) {
    return apiClient.post("/api/tickets/read", data);
  },
  deleteTicket(uuid) {
    return apiClient.delete(`/api/tickets/${uuid}`);
  },
  answerTicket(data) {
    return apiClient.post(`/api/tickets/${data.ticket}/answer`, data);
  },
  readTicketAnswer(ticketUuid) {
    return apiClient.post(`/api/tickets/${ticketUuid}/answer/read`);
  },
  updateTicketAnswer(data) {
    return apiClient.put(
      `/api/tickets/${data.ticket}/answer/${data.comment}`,
      data
    );
  },
  deleteTicketAnswer(data) {
    return apiClient.delete(
      `/api/tickets/${data.ticket}/answer/${data.comment}`
    );
  },
  // -----------------------------
  // Tickets END
  // -----------------------------
  /******************************
   * WORKSHOPS
   ******************************/
  fetchWorkshops(query) {
    const baseUrl = "/api/workshops";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },
  createWorkshop(payload) {
    return apiClient.post("/api/workshops", payload);
  },
  updateWorkshop(uuid, payload) {
    return apiClient.post(`/api/workshops/${uuid}`, payload);
  },
  fetchWorkshop(uuid) {
    return apiClient.get(`/api/workshops/${uuid}`);
  },
  fetchWorkshopAttachment(uuid) {
    return apiClient.get(`/api/workshops/${uuid}/attachments`);
  },
  deleteWorkshop(uuid) {
    return apiClient.delete(`/api/workshops/${uuid}`);
  },
  createWorkshopRegistration(payload) {
    return apiClient.post("/api/workshops/registrations", payload);
  },
  deleteWorkshopRegistration(uuid) {
    return apiClient.delete(`/api/workshops/registrations/${uuid}`);
  },
  deleteWorkshopWaitingRegistration(uuid) {
    return apiClient.delete(`/api/workshops/registrations/waiting/${uuid}`);
  },
  fetchWorkshopTopics(query) {
    const baseUrl = "/api/workshops/topics";
    return apiClient.get(this.addQueryStringToBaseUrl(baseUrl, query));
  },

  // Messages START
  fetchMessages(sortingData) {
    return apiClient.get(
      this.paginationSortingUrl("/api/messages", sortingData)
    );
  },
  fetchMessagesForUser() {
    return apiClient.get("api/users/messages");
  },
  fetchSingleMessage(id) {
    return apiClient.get(`/api/messages/${id}`);
  },
  storeMessage(data) {
    return apiClient.post("/api/messages", data);
  },
  updateMessage(data, id) {
    return apiClient.post(`/api/messages/${id}`, data);
  },
  readMessage(id) {
    return apiClient.get(`/api/users/messages/${id}/read`);
  },
  deleteMessage(messageId) {
    return apiClient.delete(`/api/messages/${messageId}`);
  },
  // -----------------------------
  // Messages END
  // -----------------------------
  // Files START
  showFile(id) {
    return apiClient.get(`/api/files/${id}`);
  },
  // -----------------------------
  // Files END
  // -----------------------------
  // Roles START
  // Roles & Permissions
  fetchAllRoles() {
    return apiClient.get("/api/roles");
  },
  fetchAllPermissions() {
    return apiClient.get("/api/permissions");
  },
  fetchUserAbilities() {
    return apiClient.get("/api/abilities");
  },
  fetchSingleRole(id) {
    return apiClient.get(`/api/roles/${id}`);
  },
  fetchSinglePermission(id) {
    return apiClient.get(`/api/permissions/${id}`);
  },
  createRole(payload) {
    return apiClient.post("/api/roles", payload);
  },
  createPermission(payload) {
    return apiClient.post("/api/permissions", payload);
  },
  updateRole(roleId, payload) {
    return apiClient.put(`/api/roles/${roleId}`, payload);
  },
  updatePermission(permissionId, payload) {
    return apiClient.put(`/api/permissions/${permissionId}`, payload);
  },
  deleteRole(roleId) {
    return apiClient.delete(`/api/roles/${roleId}`);
  },
  deletePermission(permissionId) {
    return apiClient.delete(`/api/permissions/${permissionId}`);
  },
  // -----------------------------
  // Roles END
  // -----------------------------

  // -----------------------------
  // Forum START
  // -----------------------------
  updateForumRulesAccepted(payload) {
    return apiClient.put("/api/users/forum-rules-accepted", payload);
  },
  fetchGroups(query) {
    return apiClient.get("/api/postgroups", query);
  },
  fetchGroup(groupId, query) {
    return apiClient.get(`/api/postgroups/${groupId}`, query);
  },
  fetchChannels(query) {
    return apiClient.get("/api/post-channels", query);
  },
  fetchChannel(slug, query) {
    return apiClient.get(`/api/post-channels/${slug}`, query);
  },
  fetchPosts(query) {
    return apiClient.get("/api/posts", query);
  },
  fetchPost(postId, query) {
    return apiClient.get(`/api/posts/${postId}`, query);
  },
  storePost(payload) {
    return apiClient.post("/api/posts", payload);
  },
  updatePost(postId, payload) {
    return apiClient.put(`/api/posts/${postId}`, payload);
  },
  deletePost(postId) {
    return apiClient.delete(`/api/posts/${postId}`);
  },
  storeComment(payload) {
    return apiClient.post("/api/comments", payload);
  },
  updateComment(commentId, payload) {
    return apiClient.put(`/api/comments/${commentId}`, payload);
  },
  deleteComment(commentId) {
    return apiClient.delete(`/api/comments/${commentId}`);
  },
  reportSpam(postId, payload) {
    return apiClient.post(`/api/posts/${postId}/spam`, payload);
  },
  // -----------------------------
  // Forum END
  // -----------------------------

  // -----------------------------
  // Translation START
  // -----------------------------
  translate(payload) {
    return apiClient.post("/api/translate", payload);
  },
  // -----------------------------
  // Translation END
  // -----------------------------

  // Helpers
  // -----------------------------
  paginationSortingUrl(baseUrl, sortingData) {
    let url = baseUrl + "?";
    if (sortingData) {
      if (sortingData.page) {
        url += `page=${sortingData.page}`;
      } else {
        url += "page=1";
      }
      if (sortingData.perPage && !sortingData.sortBy) {
        url += `&perPage=${sortingData.perPage}`;
      }
      if (sortingData.perPage && sortingData.sortBy) {
        url += `&perPage=${sortingData.perPage}&sortBy=${sortingData.sortBy}&sortDesc=${sortingData.sortDesc}`;
      }

      Object.entries(sortingData).forEach(([key, value]) => {
        if (
          key !== "page" &&
          key !== "perPage" &&
          key !== "sortBy" &&
          key !== "sortDesc"
        ) {
          if (value) {
            url += `&${key}=${value}`;
          }
        }
      });

      // if (sortingData.registered) {
      //   url += "&registered=true";
      // }
      // if (sortingData.invited) {
      //   url += "&invited=true";
      // }
    } else {
      url = baseUrl;
    }
    return url;
  },
  addQueryStringToBaseUrl(baseUrl, query) {
    if (query) {
      let queryString = "";

      Object.entries(query).forEach(([key, value], index) => {
        if (index === 0) {
          queryString += `?${key}=${value}`;
        } else {
          queryString += `&${key}=${value}`;
        }
      });

      return baseUrl + queryString;
    }

    return baseUrl;
  },
};
