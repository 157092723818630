<template>
  <v-menu
    v-model="activityNotificationsMenuOpen"
    offset-x
    offset-y
    :close-on-content-click="false"
    min-width="400"
    max-width="400"
    max-height="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="mr-5" v-bind="attrs" v-on="on">
        <v-badge
          bordered
          color="blue"
          :content="unreadActivityNotifications.length"
          overlap
          right
          :value="unreadActivityNotifications.length"
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card class="pa-5">
      <v-progress-linear
        v-if="loadingActivityNotifications"
        absolute
        indeterminate
        top
        color="primary"
      ></v-progress-linear>
      <v-card-title class="px-0 mt-n5 notifications-header white">
        {{ $t("backend.activities.title") }}
        <v-spacer />
        <v-menu offset-x offset-y min-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="markAllAsRead"
              :disabled="unreadActivityNotifications.length === 0"
            >
              <v-list-item-title>
                {{ $t("backend.activities.actions.markAllAsRead") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="deleteAll"
              :disabled="activityNotifications.length === 0"
            >
              <v-list-item-title>
                {{ $t("backend.activities.actions.deleteAll") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-list nav class="px-0">
        <v-list-item
          v-for="notification in activityNotifications"
          :key="notification.id"
          link
          @click="readAndPush(notification)"
        >
          <v-list-item-content>
            <p
              class="mb-0"
              :class="notification.read_at === null ? 'font-weight-bold' : ''"
            >
              {{ notification.data.message }}
            </p>
            <span class="caption mt-1 info--text">{{
              toRelativeDate(notification.created_at)
            }}</span>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!notification.read_at"
              color="info"
              icon
              @click="markAsRead(notification.id)"
            >
              <v-icon>mdi-check-circle</v-icon>
            </v-btn>
            <v-btn
              v-else
              color="error"
              icon
              @click="deleteSingle(notification.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <p v-if="activityNotifications.length === 0" class="mb-0">
        {{ $t("backend.activities.noActivities") }}
      </p>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "ActivitiesContainer",
  data() {
    return {
      activityNotificationsMenuOpen: false,
    };
  },
  computed: {
    activityNotifications() {
      return this.$store.state.ActivityNotifications.notifications;
    },
    unreadActivityNotifications() {
      return this.$store.getters["ActivityNotifications/unreadNotifications"];
    },
    loadingActivityNotifications() {
      return this.$store.state.Loading.notifications;
    },
  },
  watch: {
    activityNotificationsMenuOpen() {
      if (this.activityNotificationsMenuOpen) {
        this.fetchNotifications();
      }
    },
  },
  methods: {
    readAndPush(notification) {
      this.markAsRead(notification.id);

      if (notification.type === "App\\Notifications\\NewTicket") {
        this.$router.push(
          `/backend/tickets/show/${notification.data.ticket.uuid}`
        );
      } else if (
        notification.type === "App\\Notifications\\ResultUploaded" ||
        notification.type === "App\\Notifications\\ResultRead"
      ) {
        this.$router.push("/backend/reports");
      } else if (notification.type === "App\\Notifications\\NewReportDemand") {
        this.$router.push("/backend/reports/demands");
      }
    },
    toRelativeDate(date) {
      return DateTime.fromISO(date).setLocale(this.$i18n.locale).toRelative();
    },
    ...mapActions("ActivityNotifications", [
      "fetchNotifications",
      "markAsRead",
      "deleteSingle",
      "markAllAsRead",
      "deleteAll",
    ]),
  },
};
</script>

<style scoped>
.notifications-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}
</style>
