<template>
  <v-card class="pa-5">
    <v-card-title class="pt-0 px-0 text-h5">
      {{ $t("frontend.pages.firstRun.credentials.title") }}
    </v-card-title>
    <p>
      <i18n path="frontend.pages.firstRun.credentials.salutation" tag="p">
        <template v-slot:anrede> {{ anrede }}<br /> </template>
      </i18n>
      {{ $t("frontend.pages.firstRun.credentials.textLine1") }}
    </p>
    <p v-html="$t('frontend.pages.firstRun.credentials.textLine2')"></p>

    <v-text-field
      v-model="username"
      :label="labelUsername"
      outlined
      :rules="[rules.required]"
    ></v-text-field>
    <v-menu
      v-if="$vuetify.breakpoint.mdAndUp"
      :value="showPasswordMenu"
      :close-on-click="false"
      offset-x
      nudge-right="10"
      right
      max-width="400"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="newPassword"
          v-bind="on"
          counter
          :label="labelPassword"
          :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
          :type="newPasswordVisible ? 'text' : 'password'"
          outlined
          :rules="[rules.required, rules.password]"
          validate-on-blur
          @focus="showPasswordMenu = true"
          @click:append="newPasswordVisible = !newPasswordVisible"
          @input="checkPassword"
        ></v-text-field>
      </template>
      <v-card outlined class="pa-5">
        <v-card-title class="pt-0 px-0">
          {{ $t("frontend.pages.firstRun.credentials.passwordRules.title") }}
        </v-card-title>
        <p class="font-weight-bold">
          {{ $t("frontend.pages.firstRun.credentials.passwordRules.subtitle") }}
        </p>
        <v-list>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasMinLength" color="error"
                >mdi-close</v-icon
              >
              <v-icon v-else color="success">mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces">{{
              $t("frontend.pages.firstRun.credentials.passwordRules.rule1")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasUppercase" color="error"
                >mdi-close</v-icon
              >
              <v-icon v-else color="success">mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces">{{
              $t("frontend.pages.firstRun.credentials.passwordRules.rule2")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasNumber" color="error">mdi-close</v-icon>
              <v-icon v-else color="success">mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces">{{
              $t("frontend.pages.firstRun.credentials.passwordRules.rule3")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasSpecial" color="error"
                >mdi-close</v-icon
              >
              <v-icon v-else color="success">mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces">{{
              $t("frontend.pages.firstRun.credentials.passwordRules.rule4")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordsMatch" color="error">mdi-close</v-icon>
              <v-icon v-else color="success">mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces">{{
              $t("frontend.pages.firstRun.credentials.passwordRules.rule5")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-text-field
      v-else
      v-model="newPassword"
      counter
      :label="labelPassword"
      :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
      :type="newPasswordVisible ? 'text' : 'password'"
      outlined
      :rules="[rules.required, rules.password]"
      validate-on-blur
      @click:append="newPasswordVisible = !newPasswordVisible"
      @input="checkPassword"
    ></v-text-field>
    <v-card v-if="$vuetify.breakpoint.smAndDown" outlined class="pa-5 mb-4">
      <v-card-title class="pt-0 px-0">
        {{ $t("frontend.pages.firstRun.credentials.passwordRules.title") }}
      </v-card-title>
      <p class="font-weight-bold">
        {{ $t("frontend.pages.firstRun.credentials.passwordRules.subtitle") }}
      </p>
      <v-list>
        <v-list-item>
          <v-list-item-icon class="my-0 align-self-center">
            <v-icon v-if="!passwordHasMinLength" color="error"
              >mdi-close</v-icon
            >
            <v-icon v-else color="success">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="white-space: break-spaces">{{
            $t("frontend.pages.firstRun.credentials.passwordRules.rule1")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="my-0 align-self-center">
            <v-icon v-if="!passwordHasUppercase" color="error"
              >mdi-close</v-icon
            >
            <v-icon v-else color="success">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="white-space: break-spaces">{{
            $t("frontend.pages.firstRun.credentials.passwordRules.rule2")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="my-0 align-self-center">
            <v-icon v-if="!passwordHasNumber" color="error">mdi-close</v-icon>
            <v-icon v-else color="success">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="white-space: break-spaces">{{
            $t("frontend.pages.firstRun.credentials.passwordRules.rule3")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="my-0 align-self-center">
            <v-icon v-if="!passwordHasSpecial" color="error">mdi-close</v-icon>
            <v-icon v-else color="success">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="white-space: break-spaces">{{
            $t("frontend.pages.firstRun.credentials.passwordRules.rule4")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon class="my-0 align-self-center">
            <v-icon v-if="!passwordsMatch" color="error">mdi-close</v-icon>
            <v-icon v-else color="success">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="white-space: break-spaces">{{
            $t("frontend.pages.firstRun.credentials.passwordRules.rule5")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
    <v-text-field
      v-model="newPasswordConfirmation"
      counter
      :label="labelPasswordConfirmation"
      :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
      :type="newPasswordVisible ? 'text' : 'password'"
      outlined
      validate-on-blur
      @click:append="newPasswordVisible = !newPasswordVisible"
      :rules="[rules.required, matchingPasswords]"
      @input="checkPassword"
      @keydown.enter="onUpdatePassword"
    ></v-text-field>
    <v-card-actions class="pb-0 px-0">
      <v-spacer />
      <v-btn large depressed @click="logout">{{
        $t("general.buttons.cancel")
      }}</v-btn>
      <v-btn
        large
        depressed
        :disabled="!passwordValid"
        color="primary"
        @click="onUpdatePassword"
        >{{ $t("general.buttons.save") }}</v-btn
      >
    </v-card-actions>
    <v-overlay
      v-if="loading"
      absolute
      opacity="0.9"
      color="white"
      class="text-center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "FirstLoginChangePassword",
  data: () => ({
    loading: false,
    username: null,
    showPasswordMenu: null,
    newPassword: "",
    newPasswordConfirmation: "",
    newPasswordVisible: false,
    passwordHasNumber: false,
    passwordHasUppercase: false,
    passwordHasSpecial: false,
    passwordHasMinLength: false,
    passwordsMatch: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    anrede() {
      if (Object.keys(this.authUser).length > 0) {
        if (this.authUser.anrede) {
          return `${this.authUser.anrede} ${this.authUser.nachname}`;
        } else {
          return `${this.authUser.vorname} ${this.authUser.nachname}`;
        }
      }
      return "";
    },
    labelUsername() {
      return this.$t("frontend.pages.firstRun.credentials.labels.username");
    },
    labelPassword() {
      return this.$t("frontend.pages.firstRun.credentials.labels.password");
    },
    labelPasswordConfirmation() {
      return this.$t(
        "frontend.pages.firstRun.credentials.labels.passwordConfirmation"
      );
    },
    matchingPasswords() {
      return (
        this.newPassword === this.newPasswordConfirmation ||
        this.$t("frontend.pages.firstRun.credentials.rules.noMatch")
      );
    },
    rules() {
      return {
        required: (value) =>
          !!value ||
          this.$t("frontend.pages.firstRun.credentials.rules.required"),
        password: (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$§;%^&*+/.,-])(?=.{8,})/;
          return (
            pattern.test(value) ||
            this.$t("frontend.pages.firstRun.credentials.rules.password")
          );
        },
      };
    },
    passwordValid() {
      return (
        this.passwordHasMinLength &&
        this.passwordHasUppercase &&
        this.passwordHasNumber &&
        this.passwordHasSpecial &&
        this.passwordsMatch
      );
    },
  },
  watch: {
    passwordValid() {
      if (this.passwordValid) {
        setTimeout(() => {
          this.showPasswordMenu = false;
        }, 500);
      }
    },
  },
  methods: {
    checkPassword() {
      this.passwordHasNumber = /\d/.test(this.newPassword);
      this.passwordHasUppercase =
        /[a-z]/.test(this.newPassword) && /[A-Z]/.test(this.newPassword);
      this.passwordHasSpecial = /[!#$%&?]/.test(this.newPassword);
      this.passwordHasMinLength = this.newPassword.length >= 8;
      this.passwordsMatch =
        this.newPassword.length &&
        this.newPassword === this.newPasswordConfirmation;
    },
    async onUpdatePassword() {
      this.loading = true;

      const data = {
        username: this.username,
        password: this.newPassword,
      };

      await ApiService.updatePasswordAfterFirstLogin(data)
        .then(async () => {
          await this.$store.dispatch("User/fetchAuthUser").then(() => {
            this.$router.push({ name: "first-run-enable-two-factor-auth" });
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async logout() {
      await ApiService.logout().then(() => {
        this.$store.dispatch("User/resetState");
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style scoped></style>
