<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2">
        <v-card outlined rounded>
          <v-list nav dense>
            <v-list-item link to="/backend/system/users">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Benutzer </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/backend/system/roles-permissions">
              <v-list-item-icon>
                <v-icon>mdi-account-cancel</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Rechteverwaltung </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LayoutSystem",
};
</script>

<style scoped></style>
