<template>
  <v-card
    v-click-outside="hideResults"
    v-if="showResultsCard"
    absolute
    offset-y
    class="pa-5 overflow-y-auto"
    min-height="300"
    max-height="720"
  >
    <v-row v-if="userResults || reportResults">
      <v-col v-if="!awaitingResponse" cols="6">
        <h3 class="mb-2 ml-2">Ärzte</h3>
        <v-list nav class="ma-0 pa-0">
          <v-list-item
            v-for="(user, index) in searchResults.users"
            :key="`user-${index}`"
            color="primary"
            @click="showUserDetails(user)"
          >
            <v-list-item-content>
              <p
                class="mb-0"
                v-html="
                  highlightText(
                    `${user.lis_id} - ${user.vorname} ${user.nachname}`
                  )
                "
              ></p>
              <p
                class="caption mb-0"
                v-html="
                  highlightText(`${user.strasse}, ${user.plz} ${user.ort}`)
                "
              ></p>
              <p class="caption mb-0" v-html="highlightText(user.email)"></p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <p v-if="searchResults.users.length === 0">
          Keine Ärzte für "{{ searchQuery }}" gefunden.
        </p>
      </v-col>
      <v-col v-if="!awaitingResponse" cols="6">
        <h3 class="mb-2 ml-2">Befunde</h3>
        <v-list nav class="ma-0 pa-0">
          <v-list-item
            v-for="(report, index) in searchResults.reports"
            :key="`report-${index}`"
            color="primary"
            @click="showReport"
          >
            <v-list-item-content>
              <p
                class="mb-0"
                v-html="
                  highlightText(`${report.befund_id} - ${report.patient_data}`)
                "
              ></p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <p v-if="searchResults.reports.length === 0">
          Keine Befunde für "{{ searchQuery }}" gefunden.
        </p>
      </v-col>
    </v-row>
    <p v-else>
      <v-icon class="mr-5">mdi-magnify</v-icon> Es wurden keine Ergebnisse für
      "{{ searchQuery }}" gefunden.
    </p>

    <v-overlay color="white" :opacity="1" absolute :value="awaitingResponse">
      <v-progress-circular
        color="primary"
        indeterminate
        size="48"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SearchResults",
  data() {
    return {
      reportDialog: false,
      report: null,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    searchQuery() {
      return this.$store.state.Search.searchQuery;
    },
    searchResults() {
      return this.$store.state.Search.results;
    },
    showResultsCard() {
      return this.$store.state.Search.showResults;
    },
    awaitingResponse() {
      return this.$store.state.Loading.search;
    },
    userResults() {
      return this.searchResults.users && this.searchResults.users.length;
    },
    reportResults() {
      return this.searchResults.reports && this.searchResults.reports.length;
    },
  },
  methods: {
    hideResults() {
      this.showResults(false);
    },
    highlightText(text) {
      if (text) {
        if (this.searchQuery === "") {
          return text;
        } else {
          return text.replace(new RegExp(this.searchQuery, "gi"), (match) => {
            return (
              '<span class="primary--text font-weight-medium">' +
              match +
              "</span>"
            );
          });
        }
      }
    },
    showUserDetails(user) {
      this.setSingleUser(user);
      this.resetSearch();
      if (this.$route.name === "list-results") {
        this.$router.replace(`/backend/users/${user.uuid}`);
      } else {
        this.$router.push(`/backend/users/${user.uuid}`);
      }
    },
    showReport() {
      this.$router.push({
        name: "list-reports",
        query: { search: this.searchQuery },
      });
      // this.report = report;
      // this.reportDialog = true;
    },
    closeReport() {
      this.report = null;
      this.reportDialog = false;
    },
    ...mapActions("Search", ["showResults", "resetSearch"]),
    ...mapActions("User", ["setSingleUser"]),
  },
};
</script>

<style scoped></style>
