<template>
  <v-card outlined class="pa-5">
    <v-card-title class="pt-0 px-0">
      Registrierungen der letzten 30 Tage ({{ count }})
    </v-card-title>
    <v-card-subtitle class="px-0">
      Sortiert nach Bestätigung der Zwei-Faktor-Authentifizierung
    </v-card-subtitle>
    <v-sheet outlined rounded max-height="400" class="overflow-y-auto">
      <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
      <v-data-table
        v-else
        class="table-hover"
        :headers="tableHeaders"
        :items="collection"
        hide-default-footer
        :items-per-page="-1"
        :loading="loading"
        no-data-text="Keine ungelesenen Befunde vorhanden."
        @click:row="
          $router.push({ name: 'show-doctor', params: { id: $event.uuid } })
        "
      >
        <template v-slot:item.name="{ item }">
          {{ item.vorname }} {{ item.nachname }}
        </template>
        <template
          v-slot:item.email_verification_notification_sent_at="{ item }"
        >
          {{ toShortDate(item.emailVerificationNotificationSentAt) }}
        </template>
        <template v-slot:item.email_verified_at="{ item }">
          {{ toShortDate(item.emailVerifiedAt) }}
        </template>
        <template v-slot:item.two_factor_confirmed_at="{ item }">
          {{ toShortDate(item.twoFactorConfirmedAt) }}
        </template>
        <template v-slot:item.two_factor_type="{ item }">
          {{ item.twoFactorType === "app" ? "App" : "E-Mail" }}
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";

export default {
  name: "WidgetRecentRegistrations",
  data() {
    return {
      collection: [],
      count: 0,
      tableHeaders: [
        { text: "Name", value: "name", sortable: false },
        {
          text: "Eingeladen",
          value: "email_verification_notification_sent_at",
          sortable: false,
        },
        {
          text: "E-Mail bestätigt",
          value: "email_verified_at",
          sortable: false,
        },
        {
          text: "Abgeschlossen",
          value: "two_factor_confirmed_at",
          sortable: false,
        },
        { text: "2FA-Methode", value: "two_factor_type", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    dateThirtyDaysAgo() {
      return DateTime.now()
        .minus({ days: 30 })
        .toLocaleString(DateTime.DATE_SHORT);
    },
  },
  async mounted() {
    await this.fetchRecentRegistrations();
  },
  methods: {
    async fetchRecentRegistrations() {
      const payload = {
        params: {
          page: 1,
          perPage: 50,
          sortBy: "two_factor_confirmed_at",
          sortDesc: true,
          view: "dashboard",
        },
      };

      this.loading = true;
      await ApiService.fetchUsers(payload)
        .then((res) => {
          this.collection = res.data.data;
          this.count = res.data.meta.total;
          this.$store.commit("Pagination/SET_PAGINATION", res.data.meta);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toShortDate(date) {
      return date
        ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
        : "";
    },
  },
};
</script>

<style scoped></style>
