export const getError = (error) => {
  const errorMessage = "Whoops, there was an API error - please try again.";

  // Maybe 404
  if (!error.data) {
    return errorMessage;
  }

  // Dev debugging
  if (process.env.NODE_ENV === "development") {
    console.error(error.data);
    console.error(error.status);
    console.error(error.headers);
  }

  // Laravel validation errors
  if (error.data) {
    return error.data.errors ? error.data.errors : error.data.message;
  }

  return errorMessage;
};
