import Vue from "vue";

Vue.prototype.$hasAnyRole = function (user, roleNameToCheck) {
  if (user.roles) {
    if (Array.isArray(roleNameToCheck)) {
      return user.roles.some((role) => roleNameToCheck.includes(role));
    } else {
      return user.roles.some((role) => role === roleNameToCheck);
    }
  }

  return false;
};
