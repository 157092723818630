<template>
  <v-card outlined class="pa-5">
    <v-card-title class="pt-0 pl-0">
      Ticket {{ ticket.id }} bearbeiten
    </v-card-title>
    <v-form ref="editTicketForm">
      <v-select
        v-model="ticketCopy.category"
        :disabled="loadingCategories || $hasAnyRole(authUser, 'Arzt')"
        :items="categories"
        item-text="name"
        item-value="id"
        :loading="loadingCategories"
        return-object
        outlined
      >
      </v-select>
      <v-combobox
        v-if="ticketCopy.category && ticketCopy.category.name === 'Befund'"
        v-model="ticketCopy.subCategory"
        clearable
        :items="subCategories"
        item-text="name"
        item-value="value"
        outlined
        label="Unterkategorie"
      ></v-combobox>
      <v-text-field
        v-model="ticketCopy.subject"
        outlined
        counter
        :disabled="$hasAnyRole(authUser, 'Arzt')"
        maxlength="150"
        label="Betreff"
        :rules="[
          rules.required,
          rules.subjectMinLength,
          rules.subjectMaxLength,
        ]"
      ></v-text-field>
      <v-autocomplete
        v-if="!$hasAnyRole(authUser, 'Arzt')"
        v-model="ticketCopy.recipient"
        :disabled="loadingPersonal"
        :items="mitarbeiter"
        item-value="uuid"
        outlined
        label="Empfänger"
        :rules="[rules.required]"
        return-object
      >
        <template v-if="loadingPersonal" v-slot:append>
          <v-progress-circular
            color="primary"
            indeterminate
            size="32"
          ></v-progress-circular>
        </template>
        <template v-slot:item="{ item }">
          {{ item.vorname }} {{ item.nachname }}
        </template>
        <template v-slot:selection="{ item }">
          {{ item.vorname }} {{ item.nachname }}
        </template>
      </v-autocomplete>
      <v-select
        v-if="!$hasAnyRole(authUser, 'Arzt')"
        v-model="ticketCopy.priority"
        :disabled="loadingPriorities"
        :items="priorities"
        item-value="id"
        item-text="name"
        outlined
        return-object
        label="Priorität"
      >
        <template v-if="loadingPriorities" v-slot:append>
          <v-progress-circular
            color="primary"
            indeterminate
            size="32"
          ></v-progress-circular>
        </template>
      </v-select>
      <v-select
        v-if="!$hasAnyRole(authUser, 'Arzt')"
        v-model="ticketCopy.status"
        :disabled="loadingStatuses"
        :items="statuses"
        item-value="id"
        item-text="name"
        outlined
        return-object
        label="Status"
      >
        <template v-if="loadingStatuses" v-slot:append>
          <v-progress-circular
            color="primary"
            indeterminate
            size="32"
          ></v-progress-circular>
        </template>
      </v-select>
      <v-textarea
        v-model="ticketCopy.content"
        counter
        outlined
        label="Inhalt"
        maxlength="1000"
        :rules="[
          rules.required,
          rules.contentMinLength,
          rules.contentMaxLength,
        ]"
      ></v-textarea>
    </v-form>
    <v-card-actions>
      <v-spacer />
      <div class="actions">
        <v-btn large depressed class="mr-2" @click="closeDialog"
          >Abbrechen</v-btn
        >
        <v-btn
          large
          depressed
          :disabled="submitButtonDisabled"
          color="primary"
          @click="submitEditTicket"
          >Speichern</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { cloneDeep, isEmpty } from "lodash";

export default {
  name: "EditTicket",
  data: () => ({
    ticketCopy: {},
    formValid: false,
    formDirty: false,
    rules: {
      required: (value) => !!value || "Pflichtfeld.",
      subjectMinLength: (value) =>
        (value && value.length >= 3) || "Min. 3 Zeichen.",
      subjectMaxLength: (value) =>
        (value && value.length <= 250) || "Max. 250 Zeichen.",
      contentMinLength: (value) =>
        (value && value.length >= 6) || "Min. 6 Zeichen.",
      contentMaxLength: (value) =>
        (value && value.length <= 1000) || "Max. 1000 Zeichen.",
    },
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    ticket() {
      return this.$store.state.Tickets.singleTicket;
    },
    priorities() {
      return this.$store.state.Tickets.priorities;
    },
    categories() {
      return this.$store.state.Tickets.categories;
    },
    subCategories() {
      return this.$store.state.Tickets.subCategories;
    },
    mitarbeiter() {
      return this.$store.state.User.mitarbeiter;
    },
    statuses() {
      return this.$store.state.Tickets.statuses;
    },
    loadingPersonal() {
      return this.$store.state.Loading.users;
    },
    loadingCategories() {
      return this.$store.state.Loading.ticketCategories;
    },
    loadingPriorities() {
      return this.$store.state.Loading.ticketPriorities;
    },
    loadingStatuses() {
      return this.$store.state.Loading.ticketStatuses;
    },
    editTicketFormValid() {
      return this.$store.state.Tickets.editTicketFormValid;
    },
    editTicketFormDirty() {
      return this.$store.state.Tickets.editTicketFormDirty;
    },
    submitButtonDisabled() {
      return !this.formValid || !this.formDirty;
    },
  },
  watch: {
    ticketCopy: {
      handler() {
        // Ticket wurde bearbeitet?
        if (
          this.ticket.category.id === this.ticketCopy.category.id &&
          this.ticket.sub_category === this.ticketCopy.subCategory &&
          this.ticket.subject === this.ticketCopy.subject &&
          this.ticketCopy.recipient &&
          this.ticket.recipient.uuid === this.ticketCopy.recipient.uuid &&
          this.ticket.priority.id === this.ticketCopy.priority.id &&
          this.ticket.status.id === this.ticketCopy.status.id &&
          this.ticket.content === this.ticketCopy.content
        ) {
          this.formDirty = false;
          this.formValid = true;
        } else {
          this.formValid = this.$refs.editTicketForm.validate();
          // Setze den Wert im State, damit der Speichern-Button anklickbar wird
          // this.setValidStatusEdit(this.formValid);
          this.formDirty = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (isEmpty(this.categories)) {
      this.fetchTicketCategories();
    }
    if (isEmpty(this.priorities)) {
      this.fetchTicketPriorities();
    }
    if (isEmpty(this.statuses)) {
      this.fetchTicketStatuses();
    }
    if (isEmpty(this.mitarbeiter)) {
      this.fetchMitarbeiter();
    }
    this.ticketCopy = cloneDeep(this.ticket);
  },
  methods: {
    fullName(user) {
      return user.vorname + " " + user.nachname;
    },
    closeDialog() {
      this.$store.commit("Tickets/SET_SHOW_DIALOG", false);
      this.$store.commit("Tickets/SET_DIALOG_TYPE", "edit");
      this.ticketCopy = cloneDeep(this.ticket);
    },
    submitEditTicket() {
      if (this.formDirty) {
        const data = {
          recipient: this.ticketCopy.recipient.uuid,
          subject: this.ticketCopy.subject,
          content: this.ticketCopy.content,
          category: this.ticketCopy.category.id,
          priority: this.ticketCopy.priority.id,
          status: this.ticketCopy.status.id,
        };

        if (this.ticketCopy.subCategory) {
          data.subCategory = this.ticketCopy.subCategory.value;
        }

        this.updateTicket({ ticket: this.ticket.uuid, data: data });
        this.closeDialog();
        // this.$router.push("/Tickets");
      }
    },
    ...mapActions("Tickets", [
      "fetchTicketCategories",
      "fetchTicketPriorities",
      "fetchTicketStatuses",
      "updateTicket",
    ]),
    ...mapActions("User", ["fetchMitarbeiter"]),
  },
};
</script>

<style scoped></style>
