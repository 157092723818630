import Vue from "vue";
import Vuex from "vuex";

import * as Auth from "@/store/modules/Auth";
import * as Globals from "@/store/modules/Globals";
import * as Loading from "@/store/modules/Loading";
import * as Notifications from "@/store/modules/Notifications";
import * as User from "@/store/modules/User";
import * as Iec from "@/store/modules/Iec";
import * as Search from "@/store/modules/Search";
import * as Reports from "@/store/modules/Reports";
import * as ActivityNotifications from "@/store/modules/ActivityNotifications";
import * as Tickets from "@/store/modules/Tickets";
import * as Messages from "@/store/modules/Messages";
import * as Pagination from "@/store/modules/Pagination";
import * as IecWiki from "@/store/modules/IecWiki";
import * as RolesPermissions from "@/store/modules/RolesPermissions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Globals,
    Loading,
    Notifications,
    User,
    Iec,
    Search,
    Reports,
    ActivityNotifications,
    Tickets,
    Messages,
    Pagination,
    IecWiki,
    RolesPermissions,
  },
});
