<template>
  <v-row>
    <v-col cols="12" md="6">
      <WidgetRecentRegistrations />
    </v-col>
    <v-col cols="12" md="6">
      <WidgetUnreadReports />
    </v-col>
    <v-col cols="12" lg="6">
      <WidgetOpenTickets />
    </v-col>
    <v-col cols="12" lg="6">
      <v-card outlined class="pa-5">
        <v-card-title class="pt-0 px-0"> Aktive Befundnachfragen </v-card-title>
        <v-sheet outlined rounded>
          <v-skeleton-loader
            v-if="loadingReports"
            type="table"
          ></v-skeleton-loader>
          <v-data-table
            v-else
            class="table-hover"
            :headers="tableHeadersDemands"
            :items="reportDemands"
            hide-default-footer
            :loading="loadingDemands"
            no-data-text="Keine Nachfragen vorhanden."
            @click:row="showSingleDemand"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
            <template v-slot:item.befundId="{ item }">
              {{ item.reportData.ref_nr }}
            </template>
            <template v-slot:item.patient_data="{ item }">
              {{ item.reportData.patient }}
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card outlined class="pa-5">
        <v-card-title class="pt-0 px-0"> Statistiken </v-card-title>
        <v-btn-toggle v-model="currentStatistics" mandatory>
          <v-btn depressed>Logins</v-btn>
          <v-btn depressed>Befundansichten</v-btn>
          <v-btn depressed>Nachfragen</v-btn>
        </v-btn-toggle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import WidgetOpenTickets from "@/components/dashboards/widgets/WidgetOpenTickets.vue";
import WidgetUnreadReports from "@/components/dashboards/widgets/WidgetUnreadReports.vue";
import WidgetRecentRegistrations from "@/components/dashboards/widgets/WidgetRecentRegistrations.vue";

export default {
  name: "DashboardIgl",
  components: {
    WidgetRecentRegistrations,
    WidgetUnreadReports,
    WidgetOpenTickets,
  },
  data: () => ({
    tableHeadersReports: [
      {
        text: "Ref.-Nr.",
        value: "befund_id",
      },
      {
        text: "Arzt",
        value: "user_id",
        sortable: false,
      },
      {
        text: "Patient",
        value: "patient_data",
      },
      {
        text: "Erstelldatum",
        value: "created_at",
      },
    ],
    tableHeadersDemands: [
      {
        text: "Erstellt",
        value: "createdAt",
        sortable: false,
      },
      {
        text: "Ref.-Nr.",
        value: "befundId",
        sortable: false,
      },
      {
        text: "Betreff",
        value: "subject",
        sortable: false,
      },
      { text: "Patient", value: "patient_data", sortable: false },
    ],
    currentStatistics: null,
  }),
  computed: {
    unreadReports() {
      return this.$store.getters["Reports/unreadResults"];
    },
    tickets() {
      return this.$store.getters["Tickets/open"];
    },
    reportDemands() {
      return this.$store.state.Reports.demands;
    },
    loadingTickets() {
      return this.$store.state.Loading.tickets;
    },
    loadingReports() {
      return this.$store.state.Loading.reports;
    },
    loadingDemands() {
      return this.$store.state.Loading.reportDemands;
    },
  },
  mounted() {
    this.fetchReports();
    this.fetchDemands();
  },
  methods: {
    showSingleDemand(item) {
      this.$router.push({
        name: "befundnachfragen-list",
        query: { item: item.uuid },
      });
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS
      );
    },
    ...mapActions("Reports", ["fetchReports", "fetchDemands"]),
  },
};
</script>

<style scoped></style>
