<template>
  <v-card outlined flat class="pa-5">
    <v-card-title class="pt-0 px-0">
      <v-btn
        depressed
        large
        active-class="primary"
        class="mr-2"
        to="/backend/system/roles-permissions/roles"
        >Rollen</v-btn
      >
      <v-btn
        depressed
        large
        active-class="primary"
        to="/backend/system/roles-permissions/permissions"
        >Rechte</v-btn
      >
    </v-card-title>
    <router-view />
  </v-card>
</template>

<script>
export default {
  name: "RolesPermissions",
};
</script>

<style scoped></style>
