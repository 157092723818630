<template>
  <v-app>
    <v-app-bar app flat dark height="72">
      <router-link to="/" class="text-decoration-none">
        <div class="d-flex justify-center align-center">
          <img
            alt="IGL Logo"
            class="shrink mr-2"
            :src="require('../assets/igl_logo_2021.svg')"
            width="40"
          />
          <p class="mb-0 text-lg-h5 white--text">
            {{ $t("frontend.navbar.title") }}
          </p>
        </div>
      </router-link>
      <v-spacer />
      <v-btn to="/login" text>
        <span class="mr-2">Login</span>
        <v-icon>mdi-login</v-icon>
      </v-btn>
      <LocaleSwitcher />
    </v-app-bar>
    <v-main class="bg--light">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <v-footer app absolute dark padless>
      <v-row no-gutters justify="center">
        <v-btn
          v-for="link in footerLinks"
          :key="link.title"
          color="white"
          text
          :to="link.path"
          rounded
          class="my-5"
        >
          {{ link.title }}
        </v-btn>
        <v-col cols="12" class="py-5 text-center">
          &copy; {{ new Date().getFullYear() }} -
          <strong
            >IGL Labor GmbH - Institut für ganzheitliche Labordiagnostik</strong
          >
        </v-col>
      </v-row>
    </v-footer>
    <NotificationsContainer />
    <UpdateAvailableSnackbar />
  </v-app>
</template>

<script>
import NotificationsContainer from "../components/notifications/NotificationsContainer";
import LocaleSwitcher from "../components/LocaleSwitcher";
import UpdateAvailableSnackbar from "@/components/UpdateAvailableSnackbar";

export default {
  name: "FrontendLayout",
  components: {
    UpdateAvailableSnackbar,
    LocaleSwitcher,
    NotificationsContainer,
  },
  data() {
    return {
      footerLinks: [
        { title: "Datenschutz", path: "/datenschutz" },
        { title: "Impressum", path: "/impressum" },
        { title: "AGB", path: "/agb" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("Loading/logout", false);
  },
};
</script>

<style scoped>
.bg--light {
  background-color: #f0f2f5;
}
.text-decoration-none {
  text-decoration: none;
}
</style>
