import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  reports: [],
  report: {},
  unreadReports: 0,
  interpretations: [],
  interpretation: {},
  demands: [],
  demandMessages: [],
  unreadDemandMessages: 0,
};

export const mutations = {
  SET_RESULTS(state, reports) {
    state.reports = reports.data;
  },
  SET_SINGLE_RESULT(state, report) {
    state.report = report;
  },
  SET_UNREAD_RESULTS(state, unreadReports) {
    state.unreadReports = unreadReports;
  },

  SET_REPORT_INTERPRETATIONS(state, data) {
    state.interpretations = data;
  },
  SET_SINGLE_REPORT_INTERPRETATION(state, data) {
    state.interpretation = data;
  },

  SET_DEMANDS(state, demands) {
    state.demands = demands;
  },
  SET_DEMAND_MESSAGES(state, messages) {
    state.demandMessages = messages;
  },
  SET_UNREAD_DEMAND_MESSAGES(state, messagesCount) {
    state.unreadDemandMessages = messagesCount;
  },
  ADD_MESSAGE(state, message) {
    state.demandMessages.push(message);
  },
  REMOVE_MESSAGE(state, message) {
    state.demandMessages.splice(state.demandMessages.indexOf(message), 1);
  },
};

export const actions = {
  // Befunde
  async fetchReports({ commit, dispatch }, sortingData = null) {
    dispatch("Loading/reports", true, { root: true });
    await ApiService.fetchReports(sortingData)
      .then((res) => {
        commit("SET_RESULTS", res.data);
        commit("SET_UNREAD_RESULTS", res.data.unread);
        commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
      })
      .catch()
      .finally(() => {
        dispatch("Loading/reports", false, { root: true });
      });
  },
  async fetchReportsByUser(
    { commit, dispatch },
    { sortingData = null, userId }
  ) {
    dispatch("Loading/reports", true, { root: true });
    await ApiService.fetchReportsByUser(sortingData, userId)
      .then((res) => {
        commit("SET_RESULTS", res.data);
        commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
      })
      .catch()
      .finally(() => {
        dispatch("Loading/reports", false, { root: true });
      });
  },
  async filterReportsByUser({ commit, dispatch }, data) {
    dispatch("Loading/reports", true, { root: true });
    await ApiService.filterReportsByArzt(data)
      .then((res) => {
        commit("SET_RESULTS", res.data);
        commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
      })
      .catch()
      .finally(() => {
        dispatch("Loading/reports", false, { root: true });
      });
  },

  // Interpretationen
  async fetchReportInterpretations({ commit }) {
    commit("Loading/SET_REPORT_INTERPRETATIONS", true, { root: true });
    await ApiService.fetchReportInterpretations()
      .then((res) => {
        commit("SET_REPORT_INTERPRETATIONS", res.data);
      })
      .finally(() => {
        commit("Loading/SET_REPORT_INTERPRETATIONS", false, { root: true });
      });
  },
  async fetchSingleReportInterpretation({ commit }, id) {
    await ApiService.fetchSingleReportInterpretation(id).then((res) => {
      commit("SET_SINGLE_REPORT_INTERPRETATION", res.data);
    });
  },

  // Nachfragen
  async fetchDemands({ commit }) {
    commit("Loading/SET_REPORT_DEMANDS", true, { root: true });
    await ApiService.fetchReportDemands()
      .then((res) => {
        commit("SET_UNREAD_DEMAND_MESSAGES", res.data.meta.unreadMessagesCount);
      })
      .finally(() => {
        commit("Loading/SET_REPORT_DEMANDS", false, { root: true });
      });
  },
  async fetchDemandMessages({ commit }, demandUuid) {
    await ApiService.fetchReportDemandMessages(demandUuid).then((res) => {
      commit("SET_DEMAND_MESSAGES", res.data);
    });
  },
};

export const getters = {
  aerzte: (state) => {
    const items = state.reports.map((result) => result.user);

    items.forEach((item) => {
      item.name = `${item.nachname}, ${item.vorname}`;
    });

    return items;
  },
  unreadResults: (state) => {
    return state.reports
      ? state.reports.filter((result) => !result.read_at)
      : null;
  },
  unreadResultsCount: (state) => {
    return state.reports.length > 0
      ? state.reports.filter((result) => !result.read_at).length
      : 0;
  },
  newDemandsCount: (state) => {
    return state.demands && state.demands.length > 0
      ? state.demands.filter(
          (demand) => !demand.archivedAt || demand.hasUnreadMessages
        ).length
      : 0;
  },
  interpretationsCount: (state) => {
    return state.interpretations.filter(
      (interpretation) =>
        !interpretation.done &&
        !interpretation.cancelled &&
        !interpretation.read_at
    ).length;
  },
};
