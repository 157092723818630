<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2">
        <v-btn
          depressed
          large
          rounded
          block
          color="primary"
          class="mb-5"
          @click="openNewPostDialog"
        >
          {{ $t("backend.forum.buttons.newDiscussion") }}
        </v-btn>
        <v-sheet outlined rounded class="pa-5">
          <h2 class="mb-5">{{ $t("backend.forum.headings.topics") }}</h2>
          <v-btn
            block
            depressed
            rounded
            exact-active-class="primary"
            class="mb-5 text-none"
            :to="{
              name: 'list-channels',
            }"
          >
            {{ $t("backend.forum.buttons.allChannels") }}
          </v-btn>
          <v-btn
            v-for="channel in channels"
            :key="channel.id"
            block
            depressed
            rounded
            exact-active-class="primary"
            class="mb-5 text-none"
            :to="{
              name: 'show-channel',
              params: {
                postgroupSlug: channel.postgroup.slug,
                slug: channel.slug,
              },
            }"
          >
            {{ channel.name }}
          </v-btn>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="8">
        <router-view :key="$root.$i18n.locale" />
      </v-col>
      <v-col cols="12" md="2">
        <v-sheet outlined rounded class="pa-5 mb-5">
          <h2 class="mb-5">{{ $t("backend.forum.headings.groups") }}</h2>
          <v-btn
            v-for="group in groups"
            :key="group.slug"
            block
            depressed
            rounded
            active-class="primary"
            class="mb-5 text-none"
            :to="{
              name: 'show-group',
              params: {
                postgroupSlug: group.slug,
              },
            }"
          >
            {{ group.name }}
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>
    <CreatePostDialog
      :value="postDialog"
      @input="closeNewPostDialog"
      @submit="submitPost"
    ></CreatePostDialog>
    <ConfirmationDialog
      :value="confirmationDialog"
      overlay-opacity="0.98"
      @dialog:action="confirmForumRules"
      @dialog:close="closeConfirmationDialog"
    >
      <template v-slot:card-title>
        <v-icon color="info" left>mdi-information</v-icon>
        {{ $t("backend.forum.messages.confirmationDialog.title") }}
      </template>
      <v-responsive max-height="300px" class="overflow-y-auto text-pre-line">
        <div
          v-html="$t('backend.forum.messages.confirmationDialog.content')"
        ></div>
      </v-responsive>
      <v-form v-model="confirmationFormValid" ref="confirmationForm">
        <v-checkbox
          v-model="forumRulesAccepted"
          :label="$t('backend.forum.messages.confirmationDialog.checkboxLabel')"
          :rules="[rules.required]"
        />
      </v-form>

      <template v-slot:abort-button-text>{{
        $t("backend.forum.messages.confirmationDialog.cancel")
      }}</template>
      <template v-slot:action-button-text>{{
        $t("backend.forum.messages.confirmationDialog.confirm")
      }}</template>
    </ConfirmationDialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import CreatePostDialog from "@/components/dialogs/CreatePostDialog.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { DateTime } from "luxon";
import { mapActions } from "vuex";

export default {
  name: "LayoutForum",
  components: { ConfirmationDialog, CreatePostDialog },
  data() {
    return {
      channels: [],
      groups: [],
      postDialog: false,
      confirmationDialog: false,
      confirmationFormValid: false,
      forumRulesAccepted: false,
      loadingChannels: false,
      loadingGroups: false,
      submittingPost: false,
      rules: {
        required: (value) => !!value || "Pflichtfeld",
      },
    };
  },
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.postgroupSlug !== from.params.postgroupSlug) {
        this.fetchChannels(to.params.postgroupSlug);
      }
    },
    "$root.$i18n.locale": {
      handler() {
        this.reloadOnLanguageChange();
      },
    },
  },
  async mounted() {
    if (!this.authUser.forumRulesConfirmedAt) {
      this.confirmationDialog = true;
    }

    await this.fetchChannels();

    if (this.groups.length === 0) {
      await this.fetchGroups();
    }
  },
  methods: {
    async reloadOnLanguageChange() {
      await this.fetchAuthUser();
      await this.fetchGroups();
      await this.fetchChannels();
    },
    async fetchGroups() {
      this.loadingGroups = true;
      await ApiService.fetchGroups()
        .then((res) => {
          this.groups = res.data.data;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    async fetchChannels(postgroupSlug = null) {
      const payload = {
        params: null,
      };

      if (postgroupSlug) {
        payload.params = {
          postgroup_slug: postgroupSlug,
        };
      }

      this.loadingChannels = true;
      await ApiService.fetchChannels(payload)
        .then((res) => {
          this.channels = res.data.data;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingChannels = false;
        });
    },
    async confirmForumRules() {
      this.$refs.confirmationForm.validate();

      if (!this.confirmationFormValid) {
        return;
      }

      const payload = {
        forum_rules_confirmed_at: DateTime.now().toISO(),
      };

      await ApiService.updateForumRulesAccepted(payload)
        .then(() => {
          const notification = {
            type: "success",
            title: "Erfolg",
            message: "Forum-Regeln bestätigt",
          };

          this.$store.commit("Notifications/ADD", notification);

          this.$store.dispatch("User/fetchAuthUser");
          this.confirmationDialog = false;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        });
    },
    async submitPost(data) {
      const payload = {
        postgroup_id: data.channel.postgroup.id,
        post_channel_id: data.channel.id,
        title: data.title,
        body: data.body,
      };

      this.submittingPost = true;
      await ApiService.storePost(payload)
        .then((res) => {
          const notification = {
            type: "success",
            title: "Success",
            message: "Post created",
          };

          this.$store.commit("Notifications/ADD", notification);

          this.$router.push({
            name: "show-post",
            params: {
              channel: res.data.data.postChannel.slug,
              slug: res.data.data.slug,
            },
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.submittingPost = false;
          this.closeNewPostDialog();
        });
    },
    openNewPostDialog() {
      this.postDialog = true;
    },
    closeNewPostDialog() {
      this.postDialog = false;
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
      this.$router.back();
    },
    ...mapActions("User", ["fetchAuthUser"]),
  },
};
</script>

<style scoped></style>
