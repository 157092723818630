import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  searchQuery: null,
  results: [],
  showResults: false,
};

export const mutations = {
  SET_SEARCH_QUERY(state, query) {
    state.searchQuery = query;
  },
  SET_RESULTS(state, results) {
    if (results.data) {
      state.results = results.data;
    } else {
      state.results = results;
    }
  },
  SET_SHOW_RESULTS(state, status) {
    state.showResults = status;
  },
};

export const actions = {
  setSearchQuery({ commit }, query) {
    commit("SET_SEARCH_QUERY", query);
  },
  async fetchResults({ commit, dispatch }, search) {
    dispatch("Loading/search", true, { root: true });
    await ApiService.search(search)
      .then((res) => {
        commit("SET_RESULTS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message:
            "Fehler bei der Suche: " + (err && err.message) || err.statusText,
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/search", false, { root: true });
      });
  },
  showResults({ commit }, payload) {
    commit("SET_SHOW_RESULTS", payload);
  },
  resetSearch({ commit }) {
    commit("SET_SEARCH_QUERY", "");
    commit("SET_RESULTS", []);
    commit("SET_SHOW_RESULTS", false);
  },
};
