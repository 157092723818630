<template>
  <v-dialog v-model="warningZone" max-width="800" width="100%" persistent>
    <v-card class="pa-5">
      <v-progress-linear
        absolute
        top
        color="primary"
        :value="progress"
      ></v-progress-linear>
      <v-card-title class="pt-0 px-0"> Sind Sie noch da? </v-card-title>
      <p>Ihre Sitzung läuft demnächst aufgrund von längerer Inaktivität ab.</p>
      <p>Der automatische Logout erfolgt in:</p>
      <v-avatar color="info" size="62" class="white--text display-1">
        {{ timerCount }}
      </v-avatar>
      <v-card-actions class="pb-0 px-0">
        <v-spacer />
        <v-btn large depressed color="primary" @click="resetTimer"
          >Eingeloggt bleiben</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "AutoLogout",
  data: () => ({
    events: ["click", "mousedown", "scroll", "keypress", "load"],
    warningTimer: null,
    logoutTimer: null,
    counterTimer: null,
    warningZone: false,
    warningTimeout: 59, // Minuten
    logoutTimeout: 60, // Minuten
    timerCount: null, // Minuten
    progress: 100,
  }),
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
  },
  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    }, this);

    this.clearTimers();
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(
        this.warningMessage,
        this.warningTimeout * 60 * 1000
      );
      this.logoutTimer = setTimeout(
        this.onLogout,
        this.logoutTimeout * 60 * 1000
      );
      this.warningZone = false;
      this.timerCount = 1;
    },
    warningMessage() {
      this.warningZone = true;
      this.counterTimer = setInterval(() => {
        if (this.timerCount > 0) {
          this.timerCount--;
          this.progress = this.progress - (1 / (this.timerCount * 60)) * 100;
        } else {
          this.warningZone = false;
        }
      }, 1000);
    },
    resetTimer() {
      this.clearTimers();
      this.setTimers();
    },
    clearTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      clearInterval(this.counterTimer);
      this.progress = 100;
    },
    async onLogout() {
      this.warningZone = false;
      try {
        await this.$store.dispatch("Loading/logout", true);
        await ApiService.logout().then(async () => {
          this.$router
            .push({
              path: "/login",
              query: { redirect: this.$route.fullPath, autologout: true },
            })
            .then(() => {
              this.$store.dispatch("User/resetState");
              this.$store.dispatch("Search/resetSearch");
              this.$store.dispatch("ActivityNotifications/resetState");
            });
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
