<template>
  <v-card class="pa-5">
    <v-card-title class="pt-0 px-0">
      {{ $t("frontend.pages.firstRun.emailVerification.heading") }}
    </v-card-title>
    <p class="text-h5">
      {{ message }}
      <v-icon v-if="verificationStatus === 0" color="error"
        >mdi-close-circle-outline</v-icon
      >
      <v-icon
        v-else-if="verificationStatus === 1 || verificationStatus === 2"
        color="success"
        size="32"
        >mdi-check-circle-outline</v-icon
      >
    </p>
    <v-card-actions class="pb-0 px-0">
      <v-btn
        v-if="showResendButton"
        large
        depressed
        color="primary"
        @click="resendVerificationMail"
        >{{
          $t("frontend.pages.firstRun.emailVerification.resendButton")
        }}</v-btn
      >
    </v-card-actions>
    <v-overlay
      v-if="confirming || resending"
      absolute
      opacity="1"
      color="white"
      class="text-center"
    >
      <v-progress-circular color="primary" indeterminate> </v-progress-circular>
      <p v-if="confirming" class="mt-4 blue-grey--text">
        {{ $t("frontend.pages.firstRun.emailVerification.confirming") }}
      </p>
      <p v-else-if="resending" class="mt-4 blue-grey--text">
        {{ $t("frontend.pages.firstRun.emailVerification.resending") }}
      </p>
    </v-overlay>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { mapActions } from "vuex";

export default {
  name: "VerifyEmail",
  data: () => ({
    message: "",
    verificationStatus: -1,
    showResendButton: false,
    confirming: false,
    resending: false,
    verificationUrl: null,
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
  },
  async mounted() {
    if (this.$route.query.verification_url) {
      this.verificationUrl = this.$route.query.verification_url;
      await this.verifyEmail();
    } else if (this.authUser.email) {
      this.message = this.$t(
        "frontend.pages.firstRun.emailVerification.newConfirmationMailMessage"
      );
      this.showResendButton = true;
    } else {
      // await this.$router.replace("/login");
    }
  },
  methods: {
    async verifyEmail() {
      if (this.verificationUrl) {
        this.confirming = true;
        await ApiService.verifyEmail(this.verificationUrl)
          .then(() => {
            this.message = this.$t(
              "frontend.pages.firstRun.emailVerification.confirmedMessage"
            );
            this.verificationStatus = 1;

            this.fetchAuthUser().then(() => {
              if (!this.authUser.loginCredentialsSetAt) {
                setTimeout(() => {
                  this.$router.push("/first-run/change-password");
                }, 1500);
              } else if (!this.authUser.twoFactorConfirmedAt) {
                setTimeout(() => {
                  this.$router.push("/first-run/two-factor-authentication");
                }, 1500);
              } else {
                setTimeout(() => {
                  this.$router.push("/login");
                }, 1500);
              }
            });
          })
          .catch((err) => {
            if (err && err.status === 403) {
              this.message = this.$t(
                "frontend.pages.firstRun.emailVerification.linkExpired"
              );
              this.verificationStatus = 0;
              this.showResendButton = true;
            }
          })
          .finally(() => {
            this.confirming = false;
          });
      }
    },
    async resendVerificationMail() {
      this.resending = true;
      await ApiService.sendEmailVerificationNotification()
        .then(() => {
          this.message = this.$t(
            "frontend.pages.firstRun.emailVerification.mailResent"
          );
          this.showResendButton = false;
        })
        .catch()
        .finally(() => {
          this.resending = false;
        });
    },
    ...mapActions("User", ["fetchAuthUser"]),
  },
};
</script>

<style scoped></style>
