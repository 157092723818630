import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import router from "@/router";

export const namespaced = true;

export const state = {};

export const mutations = {
  SET_AUTHUSER(state, data) {
    state.authUser = data;
  },
};

export const actions = {
  async logout({ commit, dispatch }) {
    await ApiService.logout()
      .then(() => {
        dispatch("User/resetState", null, { root: true });
        if (router.currentRoute.name !== "login") {
          router.push({ path: "/login" });
        }
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: "Fehler beim Logout: " + getError(err),
        };

        commit("Notifications/ADD", notification, { root: true });
      });
  },
};
