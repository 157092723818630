export const namespaced = true;

export const state = {
  authUser: false,
  users: false,
  singleUser: false,
  iecSubstances: false,
  iecSubstanceGroups: false,
  request: false,
  search: false,
  notifications: false,
  reports: false,
  reportDemands: false,
  reportInterpretations: false,
  tickets: false,
  ticketsRecent: false,
  ticketSingle: false,
  ticketCategories: false,
  ticketPriorities: false,
  ticketStatuses: false,
  ticketStore: false,
  ticketAnswer: false,
  passwordConfirmation: false,
  messages: false,
  logout: false,
};

export const mutations = {
  SET_AUTHUSER(state, val) {
    state.authUser = val;
  },
  SET_USERS(state, val) {
    state.users = val;
  },
  SET_SINGLE_USER(state, val) {
    state.singleUser = val;
  },
  SET_IEC_SUBSTANCES(state, val) {
    state.iecSubstances = val;
  },
  SET_IEC_SUBSTANCE_GROUPS(state, val) {
    state.iecSubstanceGroups = val;
  },
  SET_REQUEST(state, val) {
    state.request = val;
  },
  SET_SEARCH(state, val) {
    state.search = val;
  },
  SET_NOTIFICATIONS(state, val) {
    state.notifications = val;
  },
  SET_REPORTS(state, val) {
    state.reports = val;
  },
  SET_REPORT_DEMANDS(state, val) {
    state.reportDemands = val;
  },
  SET_REPORT_INTERPRETATIONS(state, val) {
    state.reportInterpretations = val;
  },
  SET_TICKETS(state, val) {
    state.tickets = val;
  },
  SET_TICKETS_RECENT(state, val) {
    state.ticketsRecent = val;
  },
  SET_TICKET_SINGLE(state, val) {
    state.ticketSingle = val;
  },
  SET_TICKET_CATEGORIES(state, val) {
    state.ticketCategories = val;
  },
  SET_TICKET_PRIORITIES(state, val) {
    state.ticketPriorities = val;
  },
  SET_TICKET_STATUSES(state, val) {
    state.ticketCategories = val;
  },
  SET_TICKET_STORE(state, val) {
    state.ticketStore = val;
  },
  SET_TICKET_ANSWER(state, val) {
    state.ticketAnswer = val;
  },
  SET_PASSWORD_CONFIRMATION(state, val) {
    state.passwordConfirmation = val;
  },
  SET_MESSAGES(state, val) {
    state.messages = val;
  },
  SET_LOGOUT(state, val) {
    state.logout = val;
  },
};

export const actions = {
  authUser({ commit }, val) {
    commit("SET_AUTHUSER", val);
  },
  users({ commit }, val) {
    commit("SET_USERS", val);
  },
  singleUser({ commit }, val) {
    commit("SET_SINGLE_USER", val);
  },
  iecSubstances({ commit }, val) {
    commit("SET_IEC_SUBSTANCES", val);
  },
  iecSubstanceGroups({ commit }, val) {
    commit("SET_IEC_SUBSTANCE_GROUPS", val);
  },
  request({ commit }, val) {
    commit("SET_REQUEST", val);
  },
  search({ commit }, val) {
    commit("SET_SEARCH", val);
  },
  notifications({ commit }, val) {
    commit("SET_NOTIFICATIONS", val);
  },
  reports({ commit }, val) {
    commit("SET_REPORTS", val);
  },
  tickets({ commit }, val) {
    commit("SET_TICKETS", val);
  },
  ticketsRecent({ commit }, val) {
    commit("SET_TICKETS_RECENT", val);
  },
  ticketSingle({ commit }, val) {
    commit("SET_TICKET_SINGLE", val);
  },
  ticketCategories({ commit }, val) {
    commit("SET_TICKET_CATEGORIES", val);
  },
  ticketPriorities({ commit }, val) {
    commit("SET_TICKET_PRIORITIES", val);
  },
  ticketStatuses({ commit }, val) {
    commit("SET_TICKET_STATUSES", val);
  },
  ticketStore({ commit }, val) {
    commit("SET_TICKET_STORE", val);
  },
  ticketAnswer({ commit }, val) {
    commit("SET_TICKET_ANSWER", val);
  },
  passwordConfirmation({ commit }, val) {
    commit("SET_PASSWORD_CONFIRMATION", val);
  },
  logout({ commit }, val) {
    commit("SET_LOGOUT", val);
  },
};
