export const namespaced = true;

export const state = {
  drawer: true,
  anreden: [
    {
      id: 1,
      anrede: "Keine",
    },
    {
      id: 2,
      anrede: "Frau",
    },
    {
      id: 3,
      anrede: "Herr",
    },
  ],
  versandBefund: [
    {
      id: 1,
      versand: "Post",
    },
    {
      id: 2,
      versand: "Mail",
    },
    {
      id: 3,
      versand: "Kundencenter",
    },
  ],
  versandRechnung: [
    {
      id: 1,
      versand: "Post",
    },
    {
      id: 2,
      versand: "Mail",
    },
  ],
  zahlungsart: [
    {
      id: 1,
      zahlungsart: "auswählen",
    },
    {
      id: 2,
      zahlungsart: "Rechnung",
    },
    {
      id: 3,
      zahlungsart: "Kreditkarte",
    },
    {
      id: 4,
      zahlungsart: "Kreditkarte NLRF",
    },
  ],
  auftragstatus: [
    {
      id: 1,
      status: "Neu",
    },
    {
      id: 2,
      status: "RW Auftrag erfassen",
    },
    {
      id: 3,
      status: "In Arbeit",
    },
    {
      id: 4,
      status: "Befund erstellen",
    },
    {
      id: 5,
      status: "Befund erstellt",
    },
    {
      id: 6,
      status: "Freigabe Labor",
    },
    {
      id: 7,
      status: "Freigabe Rechnungswesen",
    },
    {
      id: 8,
      status: "erledigt",
    },
    {
      id: 9,
      status: "storniert",
    },
  ],
};

export const mutations = {
  SET_DRAWER(state, val) {
    state.drawer = val;
  },
};

export const actions = {
  toggleDrawer({ commit }, val) {
    commit("SET_DRAWER", val);
  },
};
