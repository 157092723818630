import axios from "axios";
import store from "@/store";
import router from "@/router";

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (process.env.NODE_ENV === "development") {
      console.log("Intercepted: ", error.response);
    }
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      Object.keys(store.state.User.authUser).length > 0
    ) {
      if (error.response.status === 401) {
        router.push({ name: "login" }).then(() => {
          store.dispatch("User/resetState");
          const notification = {
            type: "error",
            title: "Keine Berechtigung",
            message:
              "Bitte loggen Sie sich ein, um die gewünschte Seite aufzurufen.",
          };

          store.commit("Notifications/ADD", notification);
        });
      } else if (error.response.status === 419) {
        router.push({ name: "login", query: { autologout: true } }).then(() => {
          store.dispatch("User/resetState");
          const notification = {
            type: "error",
            title: "Sitzung abgelaufen",
            message:
              "Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.",
          };

          store.commit("Notifications/ADD", notification);
        });
      }
    }
    return Promise.reject(error.response);
  }
);
