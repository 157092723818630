<template>
  <v-card outlined class="pa-5">
    <v-card-title class="pt-0 px-0 text-h5">
      {{ $t("frontend.pages.firstRun.newsletter.title") }}
    </v-card-title>
    <v-sheet min-height="148">
      <v-checkbox
        v-model="newsletter"
        :label="$t('frontend.pages.firstRun.newsletter.labelCheckbox')"
      ></v-checkbox>
      <v-radio-group v-if="newsletter" v-model="language" mandatory>
        <v-radio
          value="Deutsch"
          :label="$t('frontend.pages.firstRun.newsletter.labelRadioGerman')"
        ></v-radio>
        <v-radio
          value="Englisch"
          :label="$t('frontend.pages.firstRun.newsletter.labelRadioEnglish')"
        ></v-radio>
      </v-radio-group>
    </v-sheet>
    <v-card-actions class="pb-0 px-0">
      <v-spacer />
      <v-btn large depressed :disabled="loading" @click="logout">{{
        $t("general.buttons.cancel")
      }}</v-btn>
      <v-btn
        large
        depressed
        :disabled="loading"
        :loading="loading"
        color="primary"
        @click="onSubmit"
        >{{ $t("general.buttons.save") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { mapActions } from "vuex";
import { getError } from "@/util/helpers";

export default {
  name: "FirstLoginNewsletter",
  data: () => ({
    newsletter: false,
    language: "Deutsch",
    loading: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    recipientlistId() {
      if (this.language === "Deutsch") {
        return 5781;
      } else {
        return 5799;
      }
    },
    gender() {
      if (
        this.authUser.anrede.search("Herr") !== -1 ||
        this.authUser.titel.search("Herr") !== -1
      ) {
        return "male";
      } else {
        return "female";
      }
    },
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      if (this.newsletter) {
        const data = {
          recipientlist_id: this.recipientlistId,
          email: this.authUser.email,
          gender: this.gender,
          firstname: this.authUser.vorname,
          lastname: this.authUser.nachname,
          zip: this.authUser.plz,
        };

        await ApiService.newsletterAddRecipient(data)
          .then((res) => {
            this.$store.dispatch("User/fetchAuthUser").then(() => {
              if (res.data.response.status === 409) {
                const notification = {
                  type: "info",
                  title: this.$t(
                    "frontend.pages.firstRun.newsletter.notifications.titles.alreadySubscribed"
                  ),
                  message: this.$t(
                    "frontend.pages.firstRun.newsletter.notifications.messages.alreadySubscribed"
                  ),
                };

                this.$store.commit("Notifications/ADD", notification);
              }

              if (res.data.response.status === 201) {
                const notification = {
                  type: "success",
                  title: this.$t(
                    "frontend.pages.firstRun.newsletter.notifications.titles.success"
                  ),
                  message: this.$t(
                    "frontend.pages.firstRun.newsletter.notifications.messages.success"
                  ),
                };

                this.$store.commit("Notifications/ADD", notification);
              }

              setTimeout(() => {
                this.$router.push({ name: "dashboard" });
              }, 2000);
            });
          })
          .catch((err) => {
            const notification = {
              type: "error",
              title: "Fehler",
              error: getError(err),
            };

            this.$store.commit("Notifications/ADD", notification);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        setTimeout(() => {
          this.$router.push("/backend");
        }, 2000);
      }
    },
    logout() {
      ApiService.logout().then(() => {
        this.$store.dispatch("User/resetState");
        this.$router.push("/login");
      });
    },
    ...mapActions("User", ["fetchAuthUser"]),
  },
};
</script>

<style scoped></style>
