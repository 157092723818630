import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  authUser: {},
  users: [],
  mitarbeiter: [],
  singleUser: {},
};

export const mutations = {
  SET_AUTHUSER(state, data) {
    state.authUser = data;
  },
  SET_USERS(state, payload) {
    state.users = payload.data;
  },
  SET_MITARBEITER(state, payload) {
    state.mitarbeiter = payload.data;
  },
  SET_SINGLEUSER(state, data) {
    state.singleUser = data;
  },
};

export const actions = {
  async fetchAuthUser({ commit, dispatch }) {
    dispatch("Loading/authUser", true, { root: true });
    await ApiService.fetchAuthUser()
      .then((res) => {
        commit("SET_AUTHUSER", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch("Loading/authUser", false, { root: true });
      });
  },
  async fetchUsers({ commit, dispatch }, sortingData = null) {
    dispatch("Loading/users", true, { root: true });
    await ApiService.fetchUsers(sortingData)
      .then((res) => {
        commit("SET_USERS", res.data);
        commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch("Loading/users", false, { root: true });
      });
  },
  async fetchMitarbeiter({ commit, dispatch }, sortingData = null) {
    dispatch("Loading/users", true, { root: true });
    await ApiService.fetchMitarbeiter(sortingData)
      .then((res) => {
        commit("SET_MITARBEITER", res.data);
        commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch("Loading/users", false, { root: true });
      });
  },
  async fetchSingleUser({ commit, dispatch }, id) {
    dispatch("Loading/singleUser", true, { root: true });
    await ApiService.showSingleUser(id)
      .then((res) => {
        commit("SET_SINGLEUSER", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch("Loading/singleUser", false, { root: true });
      });
  },
  async updateSingleUser({ commit }, data) {
    await ApiService.updateUser(data.data, data.id)
      .then((res) => {
        commit("SET_SINGLEUSER", res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  },
  async deleteSingleUser(id) {
    await ApiService.deleteUser(id)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  },
  async paginate({ commit, dispatch }, url) {
    dispatch("Loading/users", true, { root: true });
    await ApiService.paginate(url)
      .then((res) => {
        commit("SET_USERS", res.data);
        commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/users", false, { root: true });
      });
  },
  setAuthUser({ commit }, data) {
    commit("SET_AUTHUSER", data);
  },
  setUsers({ commit }, data) {
    commit("SET_USERS", data);
  },
  setSingleUser({ commit }, data) {
    commit("SET_SINGLEUSER", data);
  },
  resetState({ commit }) {
    commit("SET_AUTHUSER", {});
    commit("SET_USERS", []);
    commit("SET_MITARBEITER", []);
    commit("SET_SINGLEUSER", {});
  },
};
