<template>
  <v-card outlined class="pa-5">
    <v-card-title class="pt-0 pl-0"> Neues Ticket </v-card-title>
    <v-form ref="newTicketForm">
      <v-select
        v-model="ticket.category"
        :items="categories"
        item-text="name"
        item-value="id"
        :loading="loadingCategories"
        :disabled="loadingCategories"
        outlined
        label="Kategorie"
      >
      </v-select>
      <v-combobox
        v-if="ticket.category === 2"
        v-model="ticket.subCategory"
        clearable
        :items="subCategories"
        item-text="name"
        item-value="value"
        outlined
        label="Unterkategorie"
      ></v-combobox>
      <v-text-field
        v-model="ticket.subject"
        outlined
        label="Betreff"
        counter
        maxlength="150"
        :rules="[
          rules.required,
          rules.subjectMinLength,
          rules.subjectMaxLength,
        ]"
      ></v-text-field>
      <v-autocomplete
        v-model="ticket.recipient"
        :items="mitarbeiter"
        item-value="uuid"
        :item-text="fullName"
        :disabled="loadingUsers"
        :loading="loadingUsers"
        outlined
        label="Empfänger"
        :rules="[rules.required]"
      >
        <template v-slot:item="{ item }">
          {{ item.vorname }} {{ item.nachname }}
        </template>
        <template v-slot:selection="{ item }">
          {{ item.vorname }} {{ item.nachname }}
        </template>
      </v-autocomplete>
      <v-select
        v-model="ticket.priority"
        :items="priorities"
        item-value="id"
        item-text="name"
        :disabled="loadingPriorities"
        :loading="loadingPriorities"
        outlined
        label="Priorität"
      >
      </v-select>
      <v-textarea
        v-model="ticket.content"
        counter
        outlined
        label="Inhalt"
        maxlength="1000"
        :rules="[
          rules.required,
          rules.contentMinLength,
          rules.contentMaxLength,
        ]"
      ></v-textarea>
    </v-form>
    <v-card-actions>
      <v-spacer />
      <div class="actions">
        <v-btn large depressed class="mr-2" @click="closeDialog"
          >Abbrechen</v-btn
        >
        <v-btn
          large
          depressed
          :disabled="!formValid"
          color="primary"
          @click="submitNewTicket"
          >Speichern</v-btn
        >
      </div>
    </v-card-actions>
    <v-overlay
      v-if="loadingTicketStore"
      absolute
      color="white"
      opacity="0.9"
      class="text-center blue-grey--text"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <p class="mt-4">Ticket wird gespeichert...</p>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "@/services/ApiService";

export default {
  name: "NewTicket",
  data: () => ({
    ticket: {
      category: 1,
      subCategory: "Allgemein",
      recipient: "",
      subject: "",
      content: "",
      priority: 0,
    },
    formValid: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    mitarbeiter() {
      return this.$store.state.User.mitarbeiter;
    },
    priorities() {
      return this.$store.state.Tickets.priorities;
    },
    categories() {
      return this.$store.state.Tickets.categories;
    },
    subCategories() {
      return this.$store.state.Tickets.subCategories;
    },
    loadingCategories() {
      return this.$store.state.Loading.ticketCategories;
    },
    loadingPriorities() {
      return this.$store.state.Loading.ticketPriorities;
    },
    loadingStatuses() {
      return this.$store.state.Loading.ticketStatuses;
    },
    loadingUsers() {
      return this.$store.state.Loading.users;
    },
    loadingTicketStore() {
      return this.$store.state.Loading.ticketStore;
    },
    rules() {
      return {
        required: (value) => !!value || "Pflichtfeld.",
        subjectMinLength: (value) => value.length >= 3 || "Min. 3 Zeichen.",
        subjectMaxLength: (value) => value.length <= 250 || "Max. 250 Zeichen.",
        contentMinLength: (value) => value.length >= 6 || "Min. 6 Zeichen.",
        contentMaxLength: (value) =>
          (value && value.length <= 1000) || "Max. 1000 Zeichen.",
      };
    },
  },
  mounted() {
    if (this.mitarbeiter.length === 0) {
      this.fetchMitarbeiter();
    }
    if (this.categories.length === 0) {
      this.fetchTicketCategories();
    }
    if (this.priorities.length === 0) {
      this.fetchTicketPriorities();
    }
  },
  watch: {
    ticket: {
      handler() {
        // Prüfung, ob alle Felder ausgefüllt wurden
        let allValid = Object.values(this.ticket).every(
          (val) => val !== null && val !== ""
        );

        // Wenn alle Felder ausgefüllt wurden, validiere das gesamte Formular nochmal
        if (allValid) {
          this.formValid = this.$refs.newTicketForm.validate();
        }
      },
      deep: true,
    },
  },
  methods: {
    fullName(user) {
      return user.vorname + " " + user.nachname;
    },
    async submitNewTicket() {
      if (this.formValid) {
        this.$store.commit("Loading/SET_TICKET_STORE", true);
        const data = {
          user: this.ticket.user,
          recipient: this.ticket.recipient,
          subject: this.ticket.subject,
          content: this.ticket.content,
          category: this.ticket.category,
          priority: this.ticket.priority,
          status: this.ticket.status,
        };
        await ApiService.storeTicket(data)
          .then(() => {
            this.closeDialog();
            this.$store.dispatch("Tickets/fetchTickets");

            const notification = {
              type: "success",
              title: "Erfolg",
              message: "Das Ticket wurde erstellt.",
            };
            this.$store.dispatch("Notifications/add", notification);
          })
          .finally(() => {
            this.$store.commit("Loading/SET_TICKET_STORE", false);
          });
      }
    },
    closeDialog() {
      this.ticket.category = 1;
      this.ticket.recipient = "";
      this.ticket.subject = "";
      this.ticket.content = "";
      this.ticket.priority = 0;
      this.$store.commit("Tickets/SET_SHOW_DIALOG", false);
      this.$store.commit("Tickets/SET_DIALOG_TYPE", "new");
      this.$refs.newTicketForm.resetValidation();
      this.formValid = false;
    },
    ...mapActions("Tickets", [
      "fetchTicketCategories",
      "fetchTicketPriorities",
      "fetchTicketStatuses",
      "storeTicket",
    ]),
    ...mapActions("User", ["fetchMitarbeiter"]),
  },
};
</script>

<style scoped></style>
