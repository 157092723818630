import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  notifications: [],
};

export const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
};

export const actions = {
  async fetchNotifications({ commit, dispatch }) {
    dispatch("Loading/notifications", true, { root: true });
    await ApiService.fetchNotifications()
      .then((res) => {
        commit("SET_NOTIFICATIONS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/notifications", false, { root: true });
      });
  },
  markAsRead({ commit, dispatch }, id) {
    dispatch("Loading/notifications", true, { root: true });
    ApiService.markAsRead(id)
      .then((res) => {
        commit("SET_NOTIFICATIONS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/notifications", false, { root: true });
      });
  },
  deleteSingle({ dispatch }, id) {
    dispatch("Loading/notifications", true, { root: true });
    ApiService.delete(id)
      .then(() => {
        dispatch("fetchNotifications");
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/notifications", false, { root: true });
      });
  },
  markAllAsRead({ commit, dispatch }) {
    dispatch("Loading/notifications", true, { root: true });
    ApiService.markAllAsRead()
      .then((res) => {
        commit("SET_NOTIFICATIONS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/notifications", false, { root: true });
      });
  },
  deleteAll({ commit, dispatch }) {
    dispatch("Loading/notifications", true, { root: true });
    ApiService.deleteAll()
      .then((res) => {
        commit("SET_NOTIFICATIONS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
        };
        dispatch("Notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("Loading/notifications", false, { root: true });
      });
  },
  resetState({ commit }) {
    commit("SET_NOTIFICATIONS", []);
  },
};

export const getters = {
  unreadNotifications: (state) =>
    state.notifications.filter((notification) => {
      return notification.read_at === null;
    }),
};
