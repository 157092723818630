<template>
  <v-container>
    <v-responsive class="pb-4">
      <v-btn large depressed color="primary" to="/backend/users" exact>
        <v-icon>mdi-chevron-left</v-icon> Zur Übersicht
      </v-btn>
    </v-responsive>
    <v-row>
      <v-col cols="12" md="2">
        <v-card outlined rounded>
          <v-list dense nav>
            <v-list-item
              v-for="navItem in subnavItems"
              :key="navItem.title"
              :to="navItem.path"
              exact
            >
              <v-list-item-icon>
                <v-icon>{{ navItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ navItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <router-view :key="$route.fullPath" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SingleUserLayout",
  props: ["id"],
  computed: {
    subnavItems() {
      return [
        {
          icon: "mdi-account",
          title: "Details",
          path: `/backend/users/${this.$route.params.id}`,
        },
        {
          icon: "mdi-text-box-check",
          title: "Befunde",
          path: `/backend/users/${this.$route.params.id}/reports`,
        },
      ];
    },
  },
  beforeDestroy() {
    this.$store.commit("User/SET_SINGLEUSER", {});
  },
  watch: {
    "$route.params.id": {
      handler(uuid) {
        this.fetchSingleUser(uuid);
      },
    },
  },
  methods: {
    ...mapActions("User", ["fetchSingleUser"]),
  },
};
</script>

<style scoped></style>
