<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2">
        <v-card outlined>
          <v-list nav>
            <v-list-item
              link
              :disabled="ticketsLoading"
              exact
              :to="`/backend/tickets/open`"
            >
              <v-list-item-icon>
                <v-icon>mdi-ticket</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-badge
                  :content="openTicketsCount"
                  :value="openTicketsCount"
                  inline
                  color="info"
                >
                  <v-list-item-title>
                    {{ $t("backend.tickets.sidebar.open") }}
                  </v-list-item-title>
                </v-badge>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :disabled="ticketsLoading"
              exact
              :to="`/backend/tickets/closed`"
            >
              <v-list-item-icon>
                <v-icon>mdi-ticket</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-badge
                  :content="closedTicketsCount"
                  :value="closedTicketsCount"
                  inline
                  color="info"
                >
                  <v-list-item-title>
                    {{ $t("backend.tickets.sidebar.closed") }}
                  </v-list-item-title>
                </v-badge>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :disabled="ticketsLoading"
              exact
              :to="`/backend/tickets/all`"
            >
              <v-list-item-icon>
                <v-icon>mdi-ticket</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("backend.tickets.sidebar.all") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <router-view :key="$route.fullPath" />
      </v-col>
    </v-row>
    <v-dialog :value="showDialog" width="100%" max-width="960" persistent>
      <NewTicket v-if="dialogType === 'new'" />
      <EditTicket v-else-if="dialogType === 'edit'" />
    </v-dialog>
  </v-container>
</template>

<script>
import NewTicket from "@/components/tickets/NewTicket";
import EditTicket from "@/components/tickets/EditTicket";
export default {
  name: "Layout",
  components: { EditTicket, NewTicket },
  computed: {
    tickets() {
      return this.$store.state.Tickets.tickets;
    },
    openTicketsCount() {
      return this.$store.getters["Tickets/openCount"];
    },
    closedTicketsCount() {
      return this.$store.getters["Tickets/closedCount"];
    },
    showDialog() {
      return this.$store.state.Tickets.showDialog;
    },
    dialogType() {
      return this.$store.state.Tickets.dialogType;
    },
    ticketsLoading() {
      return this.$store.state.Loading.tickets;
    },
  },
};
</script>

<style scoped></style>
