var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showResultsCard)?_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideResults),expression:"hideResults"}],staticClass:"pa-5 overflow-y-auto",attrs:{"absolute":"","offset-y":"","min-height":"300","max-height":"720"}},[(_vm.userResults || _vm.reportResults)?_c('v-row',[(!_vm.awaitingResponse)?_c('v-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-2 ml-2"},[_vm._v("Ärzte")]),_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"nav":""}},_vm._l((_vm.searchResults.users),function(user,index){return _c('v-list-item',{key:`user-${index}`,attrs:{"color":"primary"},on:{"click":function($event){return _vm.showUserDetails(user)}}},[_c('v-list-item-content',[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.highlightText(
                  `${user.lis_id} - ${user.vorname} ${user.nachname}`
                )
              )}}),_c('p',{staticClass:"caption mb-0",domProps:{"innerHTML":_vm._s(
                _vm.highlightText(`${user.strasse}, ${user.plz} ${user.ort}`)
              )}}),_c('p',{staticClass:"caption mb-0",domProps:{"innerHTML":_vm._s(_vm.highlightText(user.email))}})])],1)}),1),(_vm.searchResults.users.length === 0)?_c('p',[_vm._v(" Keine Ärzte für \""+_vm._s(_vm.searchQuery)+"\" gefunden. ")]):_vm._e()],1):_vm._e(),(!_vm.awaitingResponse)?_c('v-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-2 ml-2"},[_vm._v("Befunde")]),_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"nav":""}},_vm._l((_vm.searchResults.reports),function(report,index){return _c('v-list-item',{key:`report-${index}`,attrs:{"color":"primary"},on:{"click":_vm.showReport}},[_c('v-list-item-content',[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.highlightText(`${report.befund_id} - ${report.patient_data}`)
              )}})])],1)}),1),(_vm.searchResults.reports.length === 0)?_c('p',[_vm._v(" Keine Befunde für \""+_vm._s(_vm.searchQuery)+"\" gefunden. ")]):_vm._e()],1):_vm._e()],1):_c('p',[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-magnify")]),_vm._v(" Es wurden keine Ergebnisse für \""+_vm._s(_vm.searchQuery)+"\" gefunden. ")],1),_c('v-overlay',{attrs:{"color":"white","opacity":1,"absolute":"","value":_vm.awaitingResponse}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"48"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }