<template>
  <v-snackbar bottom right vertical :value="updateExists" :timeout="-1">
    <p class="mb-0">
      <span class="font-weight-bold">Aktualisierung verfügbar!</span
      ><br />Klicken Sie <strong>NEU LADEN</strong> um die aktuelle Version zu
      laden.
    </p>
    <template v-slot:action>
      <v-btn depressed color="primary" @click="refreshApp">Neu laden</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import update from "@/mixins/update";

export default {
  name: "UpdateAvailableSnackbar",
  mixins: [update],
};
</script>

<style scoped></style>
