var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-0 pl-0"},[_vm._v(" Neues Ticket ")]),_c('v-form',{ref:"newTicketForm"},[_c('v-select',{attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","loading":_vm.loadingCategories,"disabled":_vm.loadingCategories,"outlined":"","label":"Kategorie"},model:{value:(_vm.ticket.category),callback:function ($$v) {_vm.$set(_vm.ticket, "category", $$v)},expression:"ticket.category"}}),(_vm.ticket.category === 2)?_c('v-combobox',{attrs:{"clearable":"","items":_vm.subCategories,"item-text":"name","item-value":"value","outlined":"","label":"Unterkategorie"},model:{value:(_vm.ticket.subCategory),callback:function ($$v) {_vm.$set(_vm.ticket, "subCategory", $$v)},expression:"ticket.subCategory"}}):_vm._e(),_c('v-text-field',{attrs:{"outlined":"","label":"Betreff","counter":"","maxlength":"150","rules":[
        _vm.rules.required,
        _vm.rules.subjectMinLength,
        _vm.rules.subjectMaxLength,
      ]},model:{value:(_vm.ticket.subject),callback:function ($$v) {_vm.$set(_vm.ticket, "subject", $$v)},expression:"ticket.subject"}}),_c('v-autocomplete',{attrs:{"items":_vm.mitarbeiter,"item-value":"uuid","item-text":_vm.fullName,"disabled":_vm.loadingUsers,"loading":_vm.loadingUsers,"outlined":"","label":"Empfänger","rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.vorname)+" "+_vm._s(item.nachname)+" ")]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(item.vorname)+" "+_vm._s(item.nachname)+" ")]}}]),model:{value:(_vm.ticket.recipient),callback:function ($$v) {_vm.$set(_vm.ticket, "recipient", $$v)},expression:"ticket.recipient"}}),_c('v-select',{attrs:{"items":_vm.priorities,"item-value":"id","item-text":"name","disabled":_vm.loadingPriorities,"loading":_vm.loadingPriorities,"outlined":"","label":"Priorität"},model:{value:(_vm.ticket.priority),callback:function ($$v) {_vm.$set(_vm.ticket, "priority", $$v)},expression:"ticket.priority"}}),_c('v-textarea',{attrs:{"counter":"","outlined":"","label":"Inhalt","maxlength":"1000","rules":[
        _vm.rules.required,
        _vm.rules.contentMinLength,
        _vm.rules.contentMaxLength,
      ]},model:{value:(_vm.ticket.content),callback:function ($$v) {_vm.$set(_vm.ticket, "content", $$v)},expression:"ticket.content"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"actions"},[_c('v-btn',{staticClass:"mr-2",attrs:{"large":"","depressed":""},on:{"click":_vm.closeDialog}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"large":"","depressed":"","disabled":!_vm.formValid,"color":"primary"},on:{"click":_vm.submitNewTicket}},[_vm._v("Speichern")])],1)],1),(_vm.loadingTicketStore)?_c('v-overlay',{staticClass:"text-center blue-grey--text",attrs:{"absolute":"","color":"white","opacity":"0.9"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}),_c('p',{staticClass:"mt-4"},[_vm._v("Ticket wird gespeichert...")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }