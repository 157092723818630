<template>
  <v-card outlined class="pa-5">
    <v-card-title class="pt-0 px-0"> Offene Tickets </v-card-title>
    <v-sheet outlined rounded max-height="400" class="overflow-y-auto">
      <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
      <v-data-table
        v-else
        class="table-hover"
        :headers="tableHeadersTickets"
        :items="tickets"
        hide-default-footer
        :loading="loading"
        no-data-text="Keine Tickets vorhanden."
        @click:row="showSingleTicket"
      >
        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.priority="{ item }">
          <v-chip :color="item.priority.color" label>
            {{ item.priority.name }}
          </v-chip>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="item.status.color" label>
            {{ item.status.name }}
          </v-chip>
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";

export default {
  name: "WidgetOpenTickets",
  data() {
    return {
      tableHeadersTickets: [
        {
          text: "Erstellt",
          value: "created_at",
          sortable: false,
        },
        {
          text: "Priorität",
          value: "priority",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Betreff",
          value: "subject",
          sortable: false,
        },
      ],
      tickets: [],
      loading: false,
    };
  },
  async mounted() {
    await this.fetchTickets();
  },
  methods: {
    async fetchTickets() {
      const query = {
        status: "open",
      };

      this.loading = true;
      await ApiService.fetchTickets(query)
        .then((res) => {
          this.tickets = res.data;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showSingleTicket(ticket) {
      this.$store.commit("Tickets/SET_SINGLE_TICKET", ticket);
      this.$router.push(`/backend/tickets/show/${ticket.uuid}`);
    },
    formatDate(date) {
      return date
        ? DateTime.fromISO(date).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS
          )
        : "";
    },
  },
};
</script>

<style scoped></style>
