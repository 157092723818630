var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-5",attrs:{"depressed":"","large":"","rounded":"","block":"","color":"primary"},on:{"click":_vm.openNewPostDialog}},[_vm._v(" "+_vm._s(_vm.$t("backend.forum.buttons.newDiscussion"))+" ")]),_c('v-sheet',{staticClass:"pa-5",attrs:{"outlined":"","rounded":""}},[_c('h2',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t("backend.forum.headings.topics")))]),_c('v-btn',{staticClass:"mb-5 text-none",attrs:{"block":"","depressed":"","rounded":"","exact-active-class":"primary","to":{
            name: 'list-channels',
          }}},[_vm._v(" "+_vm._s(_vm.$t("backend.forum.buttons.allChannels"))+" ")]),_vm._l((_vm.channels),function(channel){return _c('v-btn',{key:channel.id,staticClass:"mb-5 text-none",attrs:{"block":"","depressed":"","rounded":"","exact-active-class":"primary","to":{
            name: 'show-channel',
            params: {
              postgroupSlug: channel.postgroup.slug,
              slug: channel.slug,
            },
          }}},[_vm._v(" "+_vm._s(channel.name)+" ")])})],2)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('router-view',{key:_vm.$root.$i18n.locale})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-sheet',{staticClass:"pa-5 mb-5",attrs:{"outlined":"","rounded":""}},[_c('h2',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t("backend.forum.headings.groups")))]),_vm._l((_vm.groups),function(group){return _c('v-btn',{key:group.slug,staticClass:"mb-5 text-none",attrs:{"block":"","depressed":"","rounded":"","active-class":"primary","to":{
            name: 'show-group',
            params: {
              postgroupSlug: group.slug,
            },
          }}},[_vm._v(" "+_vm._s(group.name)+" ")])})],2)],1)],1),_c('CreatePostDialog',{attrs:{"value":_vm.postDialog},on:{"input":_vm.closeNewPostDialog,"submit":_vm.submitPost}}),_c('ConfirmationDialog',{attrs:{"value":_vm.confirmationDialog,"overlay-opacity":"0.98"},on:{"dialog:action":_vm.confirmForumRules,"dialog:close":_vm.closeConfirmationDialog},scopedSlots:_vm._u([{key:"card-title",fn:function(){return [_c('v-icon',{attrs:{"color":"info","left":""}},[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.$t("backend.forum.messages.confirmationDialog.title"))+" ")]},proxy:true},{key:"abort-button-text",fn:function(){return [_vm._v(_vm._s(_vm.$t("backend.forum.messages.confirmationDialog.cancel")))]},proxy:true},{key:"action-button-text",fn:function(){return [_vm._v(_vm._s(_vm.$t("backend.forum.messages.confirmationDialog.confirm")))]},proxy:true}])},[_c('v-responsive',{staticClass:"overflow-y-auto text-pre-line",attrs:{"max-height":"300px"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('backend.forum.messages.confirmationDialog.content'))}})]),_c('v-form',{ref:"confirmationForm",model:{value:(_vm.confirmationFormValid),callback:function ($$v) {_vm.confirmationFormValid=$$v},expression:"confirmationFormValid"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('backend.forum.messages.confirmationDialog.checkboxLabel'),"rules":[_vm.rules.required]},model:{value:(_vm.forumRulesAccepted),callback:function ($$v) {_vm.forumRulesAccepted=$$v},expression:"forumRulesAccepted"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }