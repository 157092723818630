export const namespaced = true;

export const state = {
  pagination: {
    currentPage: 0,
    perPageItems: [10, 20, 50, 100],
    perPage: 20,
    from: 1,
    to: 1,
    lastPage: 0,
    path: "",
    links: [],
    total: 0,
  },
  sorting: {
    sortBy: null,
    sortDesc: false,
  },
};

export const mutations = {
  SET_PAGINATION(state, payload) {
    state.pagination.currentPage = payload.current_page;
    state.pagination.perPage = payload.per_page;
    state.pagination.from = payload.from;
    state.pagination.to = payload.to;
    state.pagination.lastPage = payload.last_page;
    state.pagination.path = payload.path;
    state.pagination.links = payload.links;
    state.pagination.total = payload.total;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  },
  SET_PER_PAGE(state, payload) {
    state.pagination.perPage = payload;
  },
  RESET_PAGINATION(state) {
    state.pagination = {
      currentPage: 0,
      perPageItems: [10, 20, 50, 100],
      perPage: 20,
      from: 1,
      to: 1,
      lastPage: 0,
      path: "",
      links: [],
      total: 0,
    };
  },
};
