import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import { isEmpty } from "lodash";

import Dashboard from "@/views/backend/Dashboard.vue";
import FrontendLayout from "@/layouts/FrontendLayout.vue";
import BackendLayout from "@/layouts/BackendLayout.vue";
import Layout from "@/views/backend/users/Layout.vue";
import TicketLayout from "@/views/backend/tickets/Layout.vue";
import AccountLayout from "@/views/backend/users/profile/AccountLayout.vue";
import FirstRun from "@/views/FirstRun.vue";
import MessagesLayout from "@/views/backend/messages/MessagesLayout.vue";
import ReportsLayout from "@/views/backend/reports/ReportsLayout.vue";
import IecWikiLayout from "@/views/backend/iec/wiki/IecWikiLayout.vue";
import LayoutIglUsers from "@/views/backend/system/users/LayoutIglUsers.vue";
import LayoutSystem from "@/views/backend/system/LayoutSystem.vue";
import SingleUserLayout from "@/views/backend/users/SingleUserLayout.vue";
import RolesPermissions from "@/views/backend/system/RolesPermissions.vue";
import Ability from "@/services/Ability";
import LayoutWorkshops from "@/views/backend/workshops/LayoutWorkshops.vue";
import ApiService from "@/services/ApiService";
import GuideLayout from "@/views/backend/guide/GuideLayout.vue";
import LayoutOffices from "@/views/backend/offices/LayoutOffices.vue";
import LayoutForum from "@/views/backend/forum/LayoutForum.vue";
import LayoutPostgroups from "@/views/backend/forum/LayoutPostgroups.vue";

Vue.use(VueRouter);

const routes = [
  /**********************************
   * Frontend routes
   **********************************/
  {
    path: "/",
    component: FrontendLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "login",
        name: "login",
        meta: {
          title: "Login",
        },
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/login/two-factor-challenge",
        name: "login-two-factor-challenge",
        meta: {
          title: "Two Factor Challenge",
        },
        component: () => import("@/views/TwoFactorChallenge.vue"),
        props: (route) => ({
          query: route.query,
        }),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        meta: {
          title: "Passwort vergessen",
        },
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "reset-password/:token",
        name: "reset-password",
        meta: {
          title: "Passwort zurücksetzen",
        },
        component: () => import("@/views/ResetPassword.vue"),
        props: true,
      },
      {
        path: "first-run",
        name: "first-run",
        component: FirstRun,
        children: [
          {
            path: "verify-email",
            name: "verify-email",
            meta: {
              title: "E-Mail Verifizierung",
            },
            component: () => import("@/components/init/VerifyEmail.vue"),
          },
          {
            path: "change-password",
            name: "first-run-change-password",
            meta: {
              title: "Zugangsdaten vergeben",
            },
            component: () =>
              import("@/components/init/FirstLoginChangePassword.vue"),
          },
          {
            path: "two-factor-authentication",
            name: "first-run-enable-two-factor-auth",
            meta: {
              title: "2-Faktor-Authentifizierung aktivieren",
            },
            component: () =>
              import("@/components/init/FirstLoginTwoFactorAuth.vue"),
          },
          {
            path: "newsletter",
            name: "first-run-newsletter",
            meta: {
              title: "Newsletter abonnieren",
            },
            component: () =>
              import("@/components/init/FirstLoginNewsletter.vue"),
          },
        ],
      },
      {
        path: "datenschutz",
        name: "datenschutz",
        meta: {
          title: "Datenschutz",
        },
        component: () => import("@/views/Datenschutz.vue"),
      },
      {
        path: "impressum",
        name: "impressum",
        meta: {
          title: "Impressum",
        },
        component: () => import("@/views/Impressum.vue"),
      },
      {
        path: "agb",
        name: "agb",
        meta: {
          title: "AGB",
        },
        component: () => import("@/views/Agb.vue"),
      },
    ],
  },
  /**********************************
   * Backend routes
   **********************************/
  {
    path: "/backend",
    meta: {
      requiresAuth: true,
    },
    component: BackendLayout,
    children: [
      {
        path: "/",
        name: "dashboard",
        meta: {
          requiresAuth: true,
          title: "Dashboard",
        },
        component: Dashboard,
      },
      {
        path: "iec",
        name: "iEC Tool",
        meta: {
          requiresAuth: true,
          title: "iEC Tool",
        },
        component: () => import("@/views/backend/IecTool.vue"),
      },
      {
        path: "iec/wiki",
        meta: {
          requiresAuth: true,
        },
        component: IecWikiLayout,
        children: [
          {
            path: "/",
            name: "wiki-articles",
            meta: {
              requiresAuth: true,
              title: "iEC Lexikon",
              gate: "list iec articles",
            },
            component: () =>
              import("@/views/backend/iec/wiki/ListArticles.vue"),
          },
          {
            path: "articles",
            name: "wiki-list-articles",
            meta: {
              requiresAuth: true,
              title: "iEC Lexikon",
              gate: "list iec articles",
            },
            component: () =>
              import("@/views/backend/iec/wiki/ListArticles.vue"),
          },
          {
            path: "articles/new",
            name: "wiki-new-article",
            meta: {
              requiresAuth: true,
              title: "iEC Artikel erstellen",
              gate: "create iec articles",
            },
            component: () => import("@/views/backend/iec/wiki/NewArticle.vue"),
          },
          {
            path: "articles/edit/:id",
            name: "wiki-edit-article",
            meta: {
              requiresAuth: true,
              title: "iEC Artikel bearbeiten",
              gate: "edit iec articles",
            },
            component: () => import("@/views/backend/iec/wiki/EditArticle.vue"),
            props: true,
          },
        ],
      },
      // {
      //   path: "iec/:result",
      //   name: "iEC Tool Result",
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   component: () => import("@/views/backend/IecTool.vue"),
      // },
      {
        path: "offices",
        meta: {
          requiresAuth: true,
          admin: true,
        },
        component: LayoutOffices,
        children: [
          {
            path: "/",
            name: "list-offices",
            meta: {
              requiresAuth: true,
              title: "Praxen",
              gate: "list offices",
              admin: true,
            },
            component: () => import("@/views/backend/offices/ListOffices.vue"),
          },
          {
            path: "inactive",
            name: "list-offices-inactive",
            meta: {
              requiresAuth: true,
              title: "Praxen",
              gate: "list offices",
              admin: true,
            },
            component: () => import("@/views/backend/offices/ListOffices.vue"),
          },
          {
            path: ":id",
            name: "show-office",
            meta: {
              requiresAuth: true,
              title: "Praxis Details",
              gate: "show offices",
              admin: true,
            },
            component: () => import("@/views/backend/offices/ShowOffice.vue"),
            props: true,
          },
        ],
      },
      {
        path: "users",
        meta: {
          requiresAuth: true,
          admin: true,
        },
        component: Layout,
        children: [
          {
            path: "/",
            name: "list-doctors",
            meta: {
              requiresAuth: true,
              title: "Ärzte",
              gate: "list doctors",
              admin: true,
            },
            component: () => import("@/views/backend/users/List.vue"),
          },
          {
            path: "inactive",
            name: "list-inactive-doctors",
            meta: {
              requiresAuth: true,
              title: "Ärzte",
              gate: "list doctors",
              admin: true,
            },
            component: () => import("@/views/backend/users/List.vue"),
          },
          {
            path: "new",
            name: "new-doctor",
            meta: {
              requiresAuth: true,
              title: "Neuer Benutzer",
              gate: "create doctors",
              admin: true,
            },
            component: () => import("@/views/backend/users/NewUser.vue"),
          },
          {
            path: ":id",
            meta: {
              requiresAuth: true,
              gate: "show doctors",
              admin: true,
            },
            component: SingleUserLayout,
            props: true,
            children: [
              {
                path: "/",
                name: "show-doctor",
                meta: {
                  requiresAuth: true,
                  title: "Arzt Details",
                  gate: "show doctors",
                  admin: true,
                },
                component: () => import("@/views/backend/users/Show.vue"),
                props: true,
              },
              {
                path: "edit",
                name: "Benutzer bearbeiten",
                meta: {
                  requiresAuth: true,
                  title: "Arzt bearbeiten",
                  admin: true,
                },
                component: () => import("@/views/backend/users/Edit.vue"),
                props: true,
              },
              {
                path: "reports",
                name: "list-user-reports",
                meta: {
                  requiresAuth: true,
                  title: "Befunde",
                  gate: "list reports",
                },
                component: () =>
                  import("@/views/backend/users/ListUserReports.vue"),
                props: true,
              },
              // {
              //   path: "befunde/:befundId",
              //   name: "Befund Details",
              //   meta: {
              //      requiresAuth: true,
              //   },
              //   component: () => import("@/views/backend/users/Show.vue"),
              // },
            ],
          },
          {
            path: ":id/account",
            meta: {
              requiresAuth: true,
            },
            component: AccountLayout,
            children: [
              {
                path: "/",
                name: "account-details",
                meta: {
                  requiresAuth: true,
                  title: "Account Details",
                },
                component: () =>
                  import("@/views/backend/users/profile/Details.vue"),
              },
              {
                path: "security",
                name: "account-security",
                meta: {
                  requiresAuth: true,
                  title: "Account Sicherheit",
                },
                component: () =>
                  import("@/views/backend/users/profile/Security.vue"),
              },
              {
                path: "newsletter",
                name: "account-newsletter",
                meta: {
                  requiresAuth: true,
                  title: "Account Newsletter",
                  admin: true,
                },
                component: () =>
                  import("@/views/backend/users/profile/Newsletter.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "system",
        meta: {
          requiresAuth: true,
          gate: "access system settings",
        },
        component: LayoutSystem,
        children: [
          {
            path: "/",
            name: "system",
            meta: {
              requiresAuth: true,
              title: "Systemeinstellungen",
              gate: "access system settings",
            },
            component: () => import("@/views/backend/system/users/List.vue"),
          },
          {
            path: "roles-permissions",
            name: "roles-permissions",
            meta: {
              requiresAuth: true,
              title: "Rechteverwaltung",
              gate: "access system settings",
            },
            component: RolesPermissions,
            children: [
              {
                path: "roles",
                name: "list-roles",
                meta: {
                  requiresAuth: true,
                  title: "Nutzerrollen",
                  gate: "list roles",
                },
                component: () =>
                  import(
                    "@/views/backend/system/roles-permissions/RoleSettings.vue"
                  ),
              },
              {
                path: "roles/new",
                name: "new-role",
                meta: {
                  requiresAuth: true,
                  title: "Rolle erstellen",
                  gate: "create roles",
                },
                component: () =>
                  import(
                    "@/views/backend/system/roles-permissions/new/NewRole.vue"
                  ),
              },
              {
                path: "roles/edit/:id",
                name: "edit-role",
                meta: {
                  requiresAuth: true,
                  title: "Rolle bearbeiten",
                  gate: "edit roles",
                },
                component: () =>
                  import(
                    "@/views/backend/system/roles-permissions/edit/EditRoles.vue"
                  ),
                props: true,
              },
              {
                path: "permissions",
                name: "list-permissions",
                meta: {
                  requiresAuth: true,
                  title: "Rechte",
                  gate: "list permissions",
                },
                component: () =>
                  import(
                    "@/views/backend/system/roles-permissions/PermissionSettings.vue"
                  ),
              },
              {
                path: "permissions/edit/:id",
                name: "edit-permission",
                meta: {
                  requiresAuth: true,
                  title: "Rechte bearbeiten",
                  gate: "edit permissions",
                },
                component: () =>
                  import(
                    "@/views/backend/system/roles-permissions/edit/EditPermissions.vue"
                  ),
                props: true,
              },
              {
                path: "permissions/new",
                name: "new-permission",
                meta: {
                  requiresAuth: true,
                  title: "Rechte erstellen",
                  gate: "create permissions",
                },
                component: () =>
                  import(
                    "@/views/backend/system/roles-permissions/new/NewPermission.vue"
                  ),
              },
            ],
          },
          {
            path: "users",
            meta: {
              requiresAuth: true,
              gate: "list users",
            },
            component: LayoutIglUsers,
            children: [
              {
                path: "/",
                name: "list-system-users",
                meta: {
                  requiresAuth: true,
                  title: "Mitarbeiter",
                  gate: "list users",
                },
                component: () =>
                  import("@/views/backend/system/users/List.vue"),
              },
              {
                path: "new",
                name: "new-system-user",
                meta: {
                  requiresAuth: true,
                  title: "Mitarbeiter anlegen",
                  gate: "create users",
                },
                component: () => import("@/views/backend/system/users/New.vue"),
              },
              {
                path: ":id",
                name: "show-system-user",
                meta: {
                  requiresAuth: true,
                  title: "Mitarbeiter Details",
                  gate: "show users",
                },
                component: () =>
                  import("@/views/backend/system/users/Show.vue"),
                props: true,
              },
              {
                path: ":id/edit",
                name: "edit-system-user",
                meta: {
                  requiresAuth: true,
                  title: "Mitarbeiter bearbeiten",
                  gate: "edit users",
                },
                component: () =>
                  import("@/views/backend/system/users/Edit.vue"),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "reports",
        meta: {
          requiresAuth: true,
          gate: "list reports",
        },
        component: ReportsLayout,
        children: [
          {
            path: "/",
            name: "list-reports",
            meta: {
              requiresAuth: true,
              title: "Befunde",
              gate: "list reports",
            },
            component: () => import("@/views/backend/reports/ListReports.vue"),
          },
          {
            path: "demands",
            name: "befundnachfragen-list",
            meta: {
              requiresAuth: true,
              title: "Befundnachfragen",
              gate: "list report demands",
            },
            component: () =>
              import("@/views/backend/reports/demands/Demands.vue"),
          },
          {
            path: "demands/archived",
            name: "befundnachfragen-list-archived",
            meta: {
              requiresAuth: true,
              title: "Befundnachfragen Archiv",
              gate: "list report demands",
            },
            component: () =>
              import("@/views/backend/reports/demands/Demands.vue"),
          },
          {
            path: "interpretations",
            name: "befundinterpretation-list",
            meta: {
              requiresAuth: true,
              title: "Befundinterpretationen",
              gate: "list report interpretations",
            },
            component: () =>
              import(
                "@/views/backend/reports/interpretations/ListInterpretations"
              ),
          },
          {
            path: "upload",
            name: "reports-upload",
            meta: {
              requiresAuth: true,
              title: "Befundupload",
              gate: "upload reports",
            },
            component: () => import("@/views/backend/reports/Upload.vue"),
          },
        ],
      },
      {
        path: "tickets",
        meta: {
          requiresAuth: true,
          gate: "list tickets",
        },
        component: TicketLayout,
        children: [
          {
            path: "/",
            name: "tickets-all",
            meta: {
              requiresAuth: true,
              title: "Tickets",
              gate: "list tickets",
            },
            component: () => import("@/views/backend/tickets/List.vue"),
          },
          {
            path: ":type",
            name: "tickets",
            meta: {
              requiresAuth: true,
              title: "Tickets",
              gate: "list tickets",
            },
            component: () => import("@/views/backend/tickets/List.vue"),
            props: true,
          },
          {
            path: "show/:uuid",
            name: "show-ticket",
            meta: {
              requiresAuth: true,
              title: "Ticket Details",
              gate: "show tickets",
            },
            component: () => import("@/views/backend/tickets/Show.vue"),
            props: true,
          },
        ],
      },
      {
        path: "messages",
        meta: {
          requiresAuth: true,
          gate: "list backendmessages",
          admin: true,
        },
        component: MessagesLayout,
        children: [
          {
            path: "/",
            name: "backend-messages",
            meta: {
              requiresAuth: true,
              title: "Nachrichten",
              gate: "list backendmessages",
              admin: true,
            },
            component: () => import("@/views/backend/messages/List.vue"),
          },
          {
            path: "new",
            name: "new-backend-message",
            meta: {
              requiresAuth: true,
              title: "Nachricht erstellen",
              gate: "create backendmessages",
              admin: true,
            },
            component: () => import("@/views/backend/messages/New.vue"),
          },
          {
            path: ":id",
            name: "show-backend-message",
            meta: {
              requiresAuth: true,
              title: "Nachricht Details",
              gate: "show backendmessages",
              admin: true,
            },
            component: () => import("@/views/backend/messages/Show.vue"),
            props: true,
          },
          {
            path: ":id/edit",
            name: "edit-backend-message",
            meta: {
              requiresAuth: true,
              title: "Nachricht bearbeiten",
              gate: "edit backendmessages",
              admin: true,
            },
            component: () => import("@/views/backend/messages/Edit.vue"),
            props: true,
          },
        ],
      },
      {
        path: "workshops",
        meta: {
          requiresAuth: true,
          gate: "",
        },
        component: LayoutWorkshops,
        children: [
          {
            path: "/",
            name: "list-workshops",
            meta: {
              requiresAuth: true,
              title: "Schulungen",
              gate: "list workshops",
            },
            component: () =>
              import("@/views/backend/workshops/ListWorkshops.vue"),
          },
          {
            path: "new",
            name: "new-workshop",
            meta: {
              requiresAuth: true,
              title: "Schulung erstellen",
              gate: "create workshops",
            },
            component: () =>
              import("@/views/backend/workshops/NewWorkshop.vue"),
          },
          {
            path: ":id",
            name: "show-workshop",
            meta: {
              requiresAuth: true,
              title: "Schulung Details",
              gate: "show workshops",
            },
            component: () =>
              import("@/views/backend/workshops/ShowWorkshop.vue"),
            props: true,
          },
          {
            path: ":id/edit",
            name: "edit-workshop",
            meta: {
              requiresAuth: true,
              title: "Schulung bearbeiten",
              gate: "edit workshops",
            },
            component: () =>
              import("@/views/backend/workshops/EditWorkshop.vue"),
            props: true,
          },
        ],
      },
      {
        path: "forum",
        meta: {
          requiresAuth: true,
          title: "Forum",
        },
        component: LayoutForum,
        children: [
          {
            path: "/",
            name: "list-postgroups",
            meta: {
              requiresAuth: true,
              title: "Gruppen",
            },
            component: () => import("@/views/backend/forum/ListPostgroups.vue"),
          },
          {
            path: "channels",
            name: "list-channels",
            meta: {
              requiresAuth: true,
              title: "Channels",
            },
            component: () =>
              import("@/views/backend/forum/channels/ListChannels.vue"),
            props: true,
          },
          {
            path: ":postgroupSlug",
            meta: {
              requiresAuth: true,
              title: "Gruppen",
            },
            component: LayoutPostgroups,
            props: true,
            children: [
              {
                path: "/",
                name: "show-group",
                meta: {
                  requiresAuth: true,
                  title: "Groups",
                },
                component: () =>
                  import("@/views/backend/forum/groups/ShowGroup.vue"),
                props: true,
              },
              {
                path: "channels/:slug",
                name: "show-channel",
                meta: {
                  requiresAuth: true,
                  title: "Channel Details",
                },
                component: () =>
                  import("@/views/backend/forum/channels/ShowChannel.vue"),
                props: true,
              },
              {
                path: ":channel/posts",
                name: "list-posts",
                meta: {
                  requiresAuth: true,
                  title: "Posts",
                },
                component: () =>
                  import("@/views/backend/forum/posts/ListPosts.vue"),
                props: true,
              },
              {
                path: ":channel/:slug",
                name: "show-post",
                meta: {
                  requiresAuth: true,
                  title: "Post Details",
                },
                component: () =>
                  import("@/views/backend/forum/posts/ShowPost.vue"),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "guide",
        meta: {
          requiresAuth: true,
          title: "Handbuch",
        },
        component: GuideLayout,
        children: [
          {
            path: "/",
            name: "guide",
            meta: {
              requiresAuth: true,
              title: "Handbuch",
            },
            component: () => import("@/views/backend/guide/GuideHome.vue"),
          },
          {
            path: "dashboard",
            name: "guide-dashboard",
            meta: {
              requiresAuth: true,
              title: "Handbuch: Dashboard",
            },
            component: () => import("@/views/backend/guide/GuideDashboard.vue"),
          },
          {
            path: "iec-tool",
            name: "guide-iec-tool",
            meta: {
              requiresAuth: true,
              title: "Handbuch: iEC Tool",
            },
            component: () => import("@/views/backend/guide/GuideIecTool.vue"),
          },
          {
            path: "iec-wiki",
            name: "guide-iec-wiki",
            meta: {
              requiresAuth: true,
              title: "Handbuch: iEC Lexikon",
            },
            component: () => import("@/views/backend/guide/GuideIecWiki.vue"),
          },
          {
            path: "reports/overview",
            name: "guide-reports-overview",
            meta: {
              requiresAuth: true,
              title: "Handbuch: Befundübersicht",
            },
            component: () =>
              import("@/views/backend/guide/GuideReportsOverview.vue"),
          },
          {
            path: "reports/demands",
            name: "guide-reports-demands",
            meta: {
              requiresAuth: true,
              title: "Handbuch: Befundnachfragen",
            },
            component: () =>
              import("@/views/backend/guide/GuideReportsDemands.vue"),
          },
          {
            path: "messages",
            name: "guide-messages",
            meta: {
              requiresAuth: true,
              title: "Handbuch: Nachrichten",
            },
            component: () => import("@/views/backend/guide/GuideMessages.vue"),
          },
          {
            path: "tickets",
            name: "guide-tickets",
            meta: {
              requiresAuth: true,
              title: "Handbuch: Tickets",
            },
            component: () => import("@/views/backend/guide/GuideTickets.vue"),
          },
          {
            path: "account",
            name: "guide-account",
            meta: {
              requiresAuth: true,
              title: "Handbuch: Account",
            },
            component: () => import("@/views/backend/guide/GuideAccount.vue"),
          },
        ],
      },
      {
        path: "sources",
        name: "wiki-articles-sources",
        meta: {
          requiresAuth: true,
          title: "Quellenverzeichnis",
        },
        component: () => import("@/views/backend/WikiSources.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const abilities = Ability;

router.beforeEach(async (to, from, next) => {
  const authUser = store.state.User.authUser;
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const authenticated = !isEmpty(authUser);
  const canNavigate = to.matched.some((route) => {
    if (route.meta.gate) {
      return abilities.can(route.meta.gate);
    } else {
      return true;
    }
  });

  if (requiresAuth && !authenticated) {
    await ApiService.fetchAuthUser()
      .then((res) => {
        store.commit("User/SET_AUTHUSER", res.data.data);

        if (!store.state.User.authUser.emailVerifiedAt) {
          next({ name: "verify-email" });
        } else if (!store.state.User.authUser.loginCredentialsSetAt) {
          next({ name: "first-run-change-password" });
        } else if (!store.state.User.authUser.twoFactorConfirmedAt) {
          next({ name: "first-run-enable-two-factor-auth" });
        } else if (!store.state.User.authUser.twoFactorAuthenticated) {
          next({ name: "login-two-factor-challenge" });
        } else {
          next();
        }
      })
      .catch(() => {
        next({ name: "login", query: { redirect: to.fullPath } });
      });
  }

  if (authenticated && !canNavigate) {
    const notification = {
      type: "warning",
      title: "Keine Berechtigung",
      message:
        "Sie haben nicht die benötigte Berechtigung um diese URL aufzurufen.",
    };

    store.commit("Notifications/ADD", notification);

    // next({ name: "dashboard" });

    next(from.fullPath);
  }

  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " | Kundencenter - IGL Labor GmbH"
      : "Kundencenter - IGL Labor GmbH";
  });
});

export default router;
