import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import vuetify from "@/plugins/vuetify";
import Ability from "@/services/Ability";
import { abilitiesPlugin } from "@casl/vue";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

// Global styles
import "@/scss/main.scss";

// Prototypes
import "@/prototypes";

// Laravel echo
import "@/config/laravel-echo";

// Service Worker
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.use(abilitiesPlugin, Ability);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
