<template>
  <v-dialog :value="value" max-width="800" persistent>
    <v-form v-model="formValid" ref="createPostForm">
      <v-card class="pa-10">
        <v-card-title class="px-0 pt-0">{{
          $t("backend.forum.headings.newDiscussion")
        }}</v-card-title>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="title"
              :label="$t('backend.forum.forms.newDiscussion.title')"
              outlined
              dense
              :disabled="loading"
              required
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="selectedChannel"
              dense
              rounded
              outlined
              :items="channels"
              :disabled="loadingChannels || loading"
              :loading="loadingChannels"
              :item-text="channelItemName"
              :label="$t('backend.forum.forms.newDiscussion.channel')"
              placeholder="Kanal auswählen"
              required
              return-object
              :rules="[rules.required]"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider class="mb-8" />
        <CKEditor :editor="editor" :config="editorConfig" v-model="body" />
        <v-divider class="mb-5" />
        <v-card-actions class="px-0 pb-0">
          <v-spacer />
          <v-btn depressed :disabled="loading" @click="cancel">{{
            $t("backend.forum.forms.newDiscussion.cancel")
          }}</v-btn>
          <v-btn
            depressed
            color="primary"
            @click="createPost"
            :disabled="!formValid || loading"
            :loading="loading"
            >{{ $t("backend.forum.forms.newDiscussion.submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

import CKEditor from "@ckeditor/ckeditor5-vue2";

import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { Bold, Italic } from "@ckeditor/ckeditor5-basic-styles";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { Link } from "@ckeditor/ckeditor5-link";
import { ListProperties } from "@ckeditor/ckeditor5-list";
import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
import { Undo } from "@ckeditor/ckeditor5-undo";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";

export default {
  name: "CreatePostDialog",
  components: {
    CKEditor: CKEditor.component,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      title: null,
      body: "<p></p>",
      channels: [],
      selectedChannel: null,
      loadingChannels: false,
      formValid: false,
      editorConfig: {
        plugins: [
          Autoformat,
          Bold,
          Italic,
          BlockQuote,
          Essentials,
          Link,
          ListProperties,
          Paragraph,
          PasteFromOffice,
          Undo,
        ],
        toolbar: {
          items: [
            "bold",
            "italic",
            "|",
            "link",
            "|",
            "blockQuote",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "undo",
            "redo",
          ],
        },
        link: {
          addTargetToExternalLinks: true,
        },
      },
    };
  },
  computed: {
    rules() {
      return {
        required: (value) =>
          !!value ||
          this.$t("backend.forum.forms.newDiscussion.rules.required"),
      };
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.fetchChannels();
      }
    },
  },
  methods: {
    async fetchChannels() {
      const payload = {
        params: null,
      };

      if (this.$route.params.postgroupId) {
        payload.params = {
          postgroup_id: this.$route.params.postgroupId,
        };
      }

      this.loadingChannels = true;
      await ApiService.fetchChannels(payload)
        .then((res) => {
          this.channels = res.data.data;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingChannels = false;
        });
    },
    channelItemName(channel) {
      return `${channel.name} (${channel.postgroup.name})`;
    },
    createPost() {
      this.$refs.createPostForm.validate();
      if (!this.formValid) return;

      this.$emit("submit", {
        title: this.title,
        body: this.body,
        channel: this.selectedChannel,
      });
    },
    cancel() {
      this.$refs.createPostForm.reset();
      this.body = "<p></p>";
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped></style>
