import Vue from "vue";
import Echo from "laravel-echo";
import ApiService from "@/services/ApiService";
import store from "@/store";
import { getError } from "@/util/helpers";

window.Pusher = require("pusher-js");

Vue.prototype.$echo = new Echo({
  broadcaster: "pusher",
  key: "tQJEQCCAKFYxPQSjDqUEmdJA",
  wsHost: process.env.VUE_APP_LARAVEL_ECHO_HOST,
  wsPort: parseInt(process.env.VUE_APP_LARAVEL_ECHO_WS_PORT),
  wssPort: parseInt(process.env.VUE_APP_LARAVEL_ECHO_WS_PORT),
  cluster: "eu",
  forceTLS: process.env.VUE_APP_LARAVEL_ECHO_FORCE_TLS === "true",
  // encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        ApiService.authBroadcasting({
          socket_id: socketId,
          channel_name: channel.name,
        })
          .then((res) => {
            if (res.data.channel_data) {
              const channelData = JSON.parse(res.data.channel_data);
              ApiService.markAsOnline({ id: channelData.user_id })
                .then((res) => {
                  store.commit("User/SET_AUTHUSER", res.data.data);
                })
                .catch((err) => {
                  const notification = {
                    type: "error",
                    title: "Fehler",
                    error: getError(err),
                  };

                  store.commit("Notifications/ADD", notification);
                });
            }
            callback(false, res.data);
          })
          .catch((err) => {
            callback(true, err);
          });
      },
    };
  },
});
