<template>
  <div class="locale-switcher">
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text>{{ $i18n.locale }}</v-btn>
      </template>
      <v-list>
        <v-list-item-group
          v-model="$root.$i18n.locale"
          mandatory
          @change="onLanguageChange"
        >
          <v-list-item
            v-for="locale in locales"
            :key="locale.code"
            :value="locale.code"
          >
            <v-list-item-title>{{ locale.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import ApiService from "@/services/ApiService";

export default {
  name: "LocaleSwitcher",
  data: () => ({
    locales: getSupportedLocales(),
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
  },
  mounted() {
    if (this.authUser.language) {
      this.$root.$i18n.locale = this.authUser.language;
    }
  },
  methods: {
    onLanguageChange(language) {
      if (
        this.$route.matched.some((record) => record.path.includes("backend"))
      ) {
        const payload = {
          language,
        };

        ApiService.updateLanguage(payload, this.authUser.uuid);
      }
    },
  },
};
</script>

<style scoped></style>
