<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2">
        <v-card outlined rounded>
          <v-list nav dense>
            <v-list-item
              link
              exact
              :to="`/backend/users/${authUser.uuid}/account/`"
            >
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("backend.settings.sidebar.account") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              exact
              :to="`/backend/users/${authUser.uuid}/account/security`"
            >
              <v-list-item-icon>
                <v-icon>mdi-lock</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("backend.settings.sidebar.security") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              exact
              :to="`/backend/users/${authUser.uuid}/account/newsletter`"
            >
              <v-list-item-icon>
                <v-icon>mdi-email-newsletter</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("backend.settings.sidebar.newsletter") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <router-view :key="$route.fullPath" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AccountLayout",
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
  },
  async mounted() {
    await this.fetchAuthUser();
  },
  methods: {
    ...mapActions("User", ["fetchAuthUser"]),
  },
};
</script>

<style scoped></style>
