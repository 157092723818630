import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  articles: [],
  singleArticle: {},
};

export const mutations = {
  SET_ARTICLES(state, articles) {
    state.articles = articles.data;
  },
  SET_SINGLE_ARTICLE(state, article) {
    state.singleArticle = article;
  },
};

export const actions = {
  async fetchArticles({ commit }) {
    await ApiService.fetchWikiArticles().then((res) => {
      commit("SET_ARTICLES", res.data);
      commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
    });
  },
  async searchArticles({ commit }, search) {
    await ApiService.searchWiki(search).then((res) => {
      commit("SET_ARTICLES", res.data);
    });
  },
};
