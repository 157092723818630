<template>
  <v-card outlined class="pa-5">
    <v-card-title class="pt-0 px-0"> Ungelesene Befunde </v-card-title>
    <v-sheet outlined rounded max-height="400" class="overflow-y-auto">
      <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
      <v-data-table
        v-else
        class="table-hover"
        :headers="tableHeadersReports"
        :items="reports"
        hide-default-footer
        :items-per-page="-1"
        :loading="loading"
        no-data-text="Keine ungelesenen Befunde vorhanden."
        @click:row="$router.push('/backend/reports')"
      >
        <template v-slot:item.befund_id="{ item }">
          {{ item.befund_id ? item.befund_id : item.id }}
        </template>
        <template v-slot:item.user_id="{ item }">
          {{ item.user.nachname }}, {{ item.user.vorname }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { DateTime } from "luxon";

export default {
  name: "WidgetUnreadReports",
  data() {
    return {
      tableHeadersReports: [
        {
          text: "Ref.-Nr.",
          value: "befund_id",
        },
        {
          text: "Arzt",
          value: "user_id",
          sortable: false,
        },
        {
          text: "Patient",
          value: "patient_data",
        },
        {
          text: "Erstelldatum",
          value: "created_at",
        },
      ],
      reports: [],
      loading: false,
    };
  },
  async mounted() {
    await this.fetchReports();
  },
  methods: {
    async fetchReports() {
      this.loading = true;
      await ApiService.fetchReports(null)
        .then((res) => {
          this.reports = res.data.data;
        })
        .catch()
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      return date
        ? DateTime.fromISO(date).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS
          )
        : "";
    },
  },
};
</script>

<style scoped></style>
