import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  messages: [],
  userMessages: [],
  singleMessage: {},
};

export const mutations = {
  SET_MESSAGES(state, messages) {
    state.messages = messages.data;
  },
  SET_SINGLEMESSAGE(state, message) {
    state.singleMessage = message;
  },
  SET_USER_MESSAGES(state, messages) {
    state.userMessages = messages.data;
  },
  ADD_MESSAGE(state, message) {
    state.messages.push(message);
  },
  REMOVE_MESSAGE(state, messageToRemove) {
    state.messages = state.messages.filter(
      (message) => message.id === messageToRemove.id
    );
  },
};

export const actions = {
  async fetchMessages({ commit }) {
    commit("Loading/SET_MESSAGES", true, { root: true });
    await ApiService.fetchMessages()
      .then((res) => {
        commit("SET_MESSAGES", res.data);
        commit("Pagination/SET_PAGINATION", res.data.meta, { root: true });
      })
      .catch()
      .finally(() => {
        commit("Loading/SET_MESSAGES", false, { root: true });
      });
  },
  async fetchUserMessages({ commit }) {
    commit("Loading/SET_MESSAGES", true, { root: true });
    await ApiService.fetchMessagesForUser()
      .then((res) => {
        commit("SET_USER_MESSAGES", res.data);
      })
      .catch()
      .finally(() => {
        commit("Loading/SET_MESSAGES", false, { root: true });
      });
  },
  async fetchSingleMessage({ commit }, id) {
    commit("Loading/SET_MESSAGES", true, { root: true });
    await ApiService.fetchSingleMessage(id)
      .then((res) => {
        commit("SET_SINGLEMESSAGE", res.data.data);
      })
      .catch()
      .finally(() => {
        commit("Loading/SET_MESSAGES", false, { root: true });
      });
  },
};
